
import { isProd } from "constants/Constants";
export const prodLinks = {
  STRIPE: "https://dashboard.stripe.com/dashboard",
};
export const testLinks = {
  STRIPE: "https://dashboard.stripe.com/test/dashboard",
};

export const processors = [
  {
    displayName: "Midtrans",
    processorCode: "MIDTRANS",
    logo_url: "logos/midtrans.png",
    description: "Accepts Bank transfer, wallets and more.",
    info: [
      {
        title: "Fetch API Keys",
        content: [
          '<p>1. Login to Midtrans Dashboard (<a style="color:blue; text-decoration:none" href="https://dashboard.sandbox.midtrans.com/" target="_blank">https://dashboard.sandbox.midtrans.com/</a>)</p>',
          "<p>2. Navigate to Access Keys under Settings section from left side menu.</p>",
          "<p>3. Copy the Merchant ID, Client Key and Secret Key and configure Payorch Midtrans API credentials block.</p>",
        ],
      },
      {
        title: "Setup Webhooks",
        content: [
          '<p>1. Login to Midtrans Dashboard (<a  style="color:blue; text-decoration:none" href="https://dashboard.sandbox.midtrans.com/" target="_blank">https://dashboard.sandbox.midtrans.com/</a>)</p>',
          "<p>2. Navigate to Payment section under Settings section from left side menu.</p>",
          "<p>3. Click on Payment Settings -&gt; Notification URL</p>",
          `<p>4. Configure the following webhook URL, for Payment Notification URL<br>  <a>https://api.prtest.payorch.com/midtrans/MERCHANT_ID/webhooks</a></p>`,
        ],
      },
      {
        title: "Setup Return URLs",
        content: [
          '<p>1. Login to Midtrans Dashboard (<a  style="color:blue; text-decoration:none" href="https://dashboard.sandbox.midtrans.com/" target="_blank">https://dashboard.sandbox.midtrans.com/</a>)</p>',
          "<p>2. Navigate to Payment section under Settings section from the left side menu.</p>",
          "<p>3. Click on Payment Settings -&gt; Finish Redirect URL</p>",
          `<p>4. Configure the following redirect URL for Finish Redirect URL:<br>  <a >https://api.prtest.payorch.com/return/midtrans?merchantid=MERCHANT_ID</a></p>`,
        ],
      },
    ],
  },
  {
    displayName: "Adyen",
    processorCode: "ADYEN",
    logo_url: "logos/adyen.png",
    description: "Accepts Bank transfer, wallets and more.",
    info: [
      {
        title: "Fetch API Keys",
        content: [
          '<p>1. Login to Adyen Dashboard (<a  style="color:blue; text-decoration:none"  href="https://ca-test.adyen.com/ca/ca/" target="_blank">https://ca-test.adyen.com/ca/ca/</a>)</p>',
          "<p>2. Navigate to API credentials under Developers section from the left side menu.</p>",
          "<p>3. Create new credentials of type Web service user, if not already exists.</p>",
          "<p>4. Configure sufficient roles for the API while generating. Basic roles require all the webhook-related roles under the ACCOUNT section and all the roles under UNCATEGORIZED.</p>",
          "<p>5. Copy the username and API Key and configure them in the Payorch Adyen API credentials block.</p>",
        ],
      },
      {
        title: "Setup Webhooks",
        content: [
          '<p>1. Login to Adyen Dashboard (<a style="color:blue; text-decoration:none" href="https://ca-test.adyen.com/ca/ca/" target="_blank">https://ca-test.adyen.com/ca/ca/</a>)</p>',
          "<p>2. Navigate to Webhooks under the Developers section from the left side menu.</p>",
          "<p>3. Add a new webhook configuration. Select Standard Webhook and choose all the event types.</p>",
          `<p>4. Configure the following webhook URL for Payment Notification URL:<br>  <a>https://api.prtest.payorch.com/adyen/MERCHANT_ID/webhooks</a></p>`,
        ],
      },
      {
        title: "Configure Additional Data",
        content: [
          '<p>1. Login to Adyen Dashboard (<a style="color:blue; text-decoration:none" href="https://ca-test.adyen.com/ca/ca/" target="_blank">https://ca-test.adyen.com/ca/ca/</a>)</p>',
          "<p>2. Navigate to Additional data under the Developers section from the left side menu.</p>",
          "<p>3. Select all the required data. Bank, Card, and 3DSecure are mandatory.</p>",
        ],
      },
    ],
  },
  {
    displayName: "XENDIT",
    processorCode: "XENDIT",
    logo_url: "logos/xendit-logo.jpg",
    description: "Accepts Bank transfer, wallets and more.",
    info: [
      {
        title: "Fetch API Keys",
        content: [
          '<p>1. Login to Xendit Dashboard (<a style="color:blue; text-decoration:none" href="https://dashboard.xendit.co/home" target="_blank">https://dashboard.xendit.co/home</a>)</p>',
          "<p>2. Select Settings under Configuration section on the left side menu.</p>",
          "<p>3. Go to the Developers block and click API keys.</p>",
          "<p>4. Click on Generate Secret key. While generating the secret key, select Write permissions for Money-in (required for payments) and Money-out (required for payments) products.</p>",
          "<p>5. Copy the API Key and set it to the API key in the Payorch Xendit API credentials block.</p>",
        ],
      },
      {
        title: "Setup Webhooks",
        content: [
          '<p>1. Login to Xendit Dashboard (<a style="color:blue; text-decoration:none" href="https://dashboard.xendit.co/home" target="_blank">https://dashboard.xendit.co/home</a>)</p>',
          "<p>2. Select Settings under Configuration section on the left side menu.</p>",
          "<p>3. Go to the Developers block and click Webhooks.</p>",
          `<p>4. Set the following webhook URL for the following products: FIXED VIRTUAL ACCOUNTS, RETAIL OUTLETS (OTC), CARDS, PAYMENT REQUEST, Unified Refunds, PAYLATER, QR CODES, DISBURSEMENT, and E-WALLETS.<br> <a>https://api.prtest.payorch.com/xendit/MERCHANT_ID/webhooks</a></p>`,
        ],
      },
    ],
  },
  {
    displayName: "DOKU",
    processorCode: "DOKU",
    logo_url: "logos/doku-logo.jpg",
    description: "Accepts Bank transfer, wallets and more.",
    info: [
      {
        title: "Fetch API Credentials",
        content: [
          '<p>1. Login to DOKU Dashboard (<a style="color:blue; text-decoration:none" href="https://sandbox.doku.com/bo/login" target="_blank">https://sandbox.doku.com/bo/login</a>)</p>',
          "<p>2. Navigate to API Keys under the Integration section from the left side menu.</p>",
          "<p>3. Copy the Client ID and Secret Key and configure them in the Payorch DOKU API credentials block.</p>",
        ],
      },
    ],
  },
  {
    displayName: "IPaymu",
    processorCode: "IPAYMU",
    logo_url: "logos/ipaymu-logo.png",
    description: "Accepts Bank transfer, wallets and more.",
    info: [
      {
        title: "Fetch API Credentials",
        content: [
          '<p>1. Login to IPaymu Dashboard (<a style="color:blue; text-decoration:none" href="https://my.ipaymu.com/dashboard" target="_blank">https://my.ipaymu.com/dashboard</a>)</p>',
          "<p>2. Navigate to API Integration from the left side menu.</p>",
          "<p>3. Copy the VA Account number and API Key, and configure them in the Payorch IPaymu API credentials.</p>",
        ],
      },
    ],
  },
  // {
  //   displayName: "Stripe",
  //   processorCode: "STRIPE",
  //   logo_url: "logos/STRIPE.svg",
  //   info: [
  //     {
  //       title: "Fetch API Credentials",
  //       content: [
  //         "<p>1. Login to Stripe Dashboard (<a href='https://dashboard.stripe.com/test/dashboard' target='_blank'>https://dashboard.stripe.com/test/dashboard</a>)</p>",
  //         "<p>2. Go to Developers section and API Keys tab.</p>",
  //         "<p>3. Copy the Secret key, and configure them in the Payorch Stripe credentials section. If there is no secret key exists, please generate.</p>",
  //       ],
  //     },
  //     {
  //       title: "Setup Webhooks",
  //       content: [
  //         "<p>1. Login to Stripe Dashboard (<a href='https://dashboard.stripe.com/test/dashboard' target='_blank'>https://dashboard.stripe.com/test/dashboard</a>)</p>",
  //         "<p>2. Go to Developers section and Webhooks tab.</p>",
  //         "<p>3. Click on Add Endpoint and set the Endpoint URL to <a>https://api.prtest.payorch.com/stripe/MERCHANT_ID/webhooks</a></p>",
  //         "<p>4. Select all teh events under Charge, Mandate, Payment Intent, Payout.</p>",
  //       ],
  //     }
  //   ],
  // },
  {
    displayName: "Checkout.com",
    processorCode: "CHECKOUT",
    logo_url: "logos/CHECKOUT.svg",
    description: "Accepts Bank transfer, wallets and more.",
    info: [
      {
        title: "Fetch API Credentials",
        content: [
          '<p>1. Login to Checkout.com Dashboard (<a style="color:blue; text-decoration:none" href="https://dashboard.sandbox.checkout.com/" target="_blank">https://dashboard.sandbox.checkout.com/</a>)</p>',
          "<p>2. Go to Keys tab under Developers section.</p>",
          "<p>3. Copy the Secret key, and configure them in the Payorch Checkout.com credentials section. If there is no secret key exists, please generate.</p>",
        ],
      },
      {
        title: "Setup Webhooks",
        content: [
          '<p>1. Login to Checkout.com Dashboard (<a style="color:blue; text-decoration:none" href="https://dashboard.sandbox.checkout.com/" target="_blank">https://dashboard.sandbox.checkout.com/</a>)</p>',
          "<p>2. Go to Webhooks tab under Developers section.</p>",
          `<p>3. Click on new Webhook and set the Endpoint URL to <a>https://api.prtest.payorch.com/checkout/MERCHANT_ID/webhooks</a></p>`,
          "<p>4. Select all the events for categories Card Payout, Gateway and sessions.</p>",
        ],
      },
    ],
  },
  {
    displayName: "Tap",
    processorCode: "TAP",
    logo_url: "logos/TAP.svg",
    description: "Accepts Bank transfer, wallets and more.",
    info: [
      {
        title: "Fetch API Credentials",
        content: [
          '<p>1. Login to Tap Dashboard (<a style="color:blue; text-decoration:none" href="https://businesses.tap.company" target="_blank">https://businesses.tap.company</a>)</p>',
          "<p>2. Go to goSell tab and section API Credentials.</p>",
          "<p>3. Copy the Secret key, and configure them in the Payorch Tap credentials section.</p>",
        ],
      },
      {
        title: "Setup Webhooks",
        content: [
          "<p>1. No manual configuration is required for webhok setup.</p>",
        ],
      },
    ],
  },
  {
    displayName: "Myfatoorah",
    processorCode: "MYFATOORAH",
    logo_url: "logos/MYFATOORAH.svg",
    description: "Accepts Bank transfer, wallets and more.",
    info: [
      {
        title: "Fetch API Credentials",
        content: [
          '<p>1. Login to Myfatoorah Dashboard (<a style="color:blue; text-decoration:none" href="https://demo.myfatoorah.com/En/All/Account/LogIn" target="_blank">https://demo.myfatoorah.com/En/All/Account/LogIn</a>)</p>',
          "<p>2. Go to API Key under Integration Setting section.</p>",
          "<p>3. Copy the Token, and configure them in the Payorch Myfatoorah credentials section.</p>",
        ],
      },
      {
        title: "Setup Webhooks",
        content: [
          '<p>1. Login to Myfatoorah Dashboard (<a style="color:blue; text-decoration:none" href="https://demo.myfatoorah.com/En/All/Account/LogIn" target="_blank">https://demo.myfatoorah.com/En/All/Account/LogIn</a>)</p>',
          "<p>2. Go to Webhook Settings under Integration Setting section.</p>",
          "<p>3. Enable Webhook and select all the webhook events.</a></p>",
        ],
      },
    ],
  },
  {
    displayName: "Moyasar",
    processorCode: "MOYASAR",
    logo_url: "logos/MOYASAR.svg",
    description: "Accepts Bank transfer, wallets and more.",
    info: [
      {
        title: "Fetch API Credentials",
        content: [
          '<p>1. Login to Moyasar Dashboard (<a style="color:blue; text-decoration:none" href="https://dashboard.moyasar.com/login/new" target="_blank">https://dashboard.moyasar.com/login/new</a>)</p>',
          "<p>2. Go to Settings section and API Keys tab.</p>",
          "<p>3. Copy the Secret key of respective environment, and configure them in the Payorch Moyasar credentials section.</p>",
        ],
      },
      {
        title: "Setup Webhooks",
        content: [
          '<p>1. Login to Moyasar Dashboard (<a style="color:blue; text-decoration:none" href="https://dashboard.moyasar.com/login/new" target="_blank">https://dashboard.moyasar.com/login/new</a>)</p>',
          "<p>2. Go to Settings section and Webhooks tab.</p>",
          `<p>3. Click on Add Webhook and set the Endpoint URL to <a>https://api.prtest.payorch.com/moyasar/MERCHANT_ID/webhooks</a></p>`,
        ],
      },
    ],
  },
  {
    displayName: "Hyperpay",
    processorCode: "HYPERPAY",
    logo_url: "logos/HYPERPAY.svg",
    description: "Accepts Bank transfer, wallets and more.",
    info: [
      {
        title: "Fetch API Credentials",
        content: [
          "<p>1. Login to HyperPay Dashboard</p>",
          "<p>2. Go to Administartion -> Account Data -> Merchant /Channel section.</p>",
          "<p>3. Copy the access token of respective environment, and configure them in the Payorch Hyperpay credentials section.</p>",
          "<p>4. Get the Merchant Entity Identifier from Hyperpay team and configure them in the Payorch Hyperpay credentials section.</p>",
        ],
      },
      {
        title: "Setup Webhooks",
        content: [
          "<p>1. Login to Hyperpay Dashboard</p>",
          "<p>2. Go to Administration -> Webhooks section.</p>",
          `<p>3. Set the Endpoint URL to <a>https://api.prtest.payorch.com/hyperpay/MERCHANT_ID/webhooks</a></p>`,
          "<p>4. Select the notification type PAYMENTS and select all the fields.</p>",
        ],
      },
    ],
  },
  {
    displayName: "StcPay",
    processorCode: "STCPAY",
    logo_url: "logos/STCPAY.svg",
    description: "Accepts Bank transfer, wallets and more.",
    info: [
      {
        title: "Fetch API Credentials",
        content: [
          "<p>1. Get the Client Id, Client Secret from StcPay Dashboard and configure in Payorch StcPay credenrials section.</p>",
          "<p>2. Configure the Branch Id, Teller Id, Device Id and the URL provided by StcPay.</p>",
        ],
      },
    ],
  },
  {
    displayName: "Paytabs",
    processorCode: "PAYTABS",
    logo_url: "logos/PAYTABS.svg",
    description: "Accepts Bank transfer, wallets and more.",
    info: [
      {
        title: "Fetch API Credentials",
        content: [
          '<p>1. Login to Paytabs Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.paytabs.sa/merchant/login" target="_blank">https://merchant.paytabs.sa/merchant/login</a>)</p>',
          "<p>2. Go to API Keys & Key Management section under Developers.</p>",
          "<p>3. Copy the Server Key of API Key and configure them in the Payorch Paytabs credentials section.</p>",
          "<p>4. Go to Profile section, copy the Profile ID and configure them in the Payorch Paytabs credentials section.</p>",
        ],
      },
      {
        title: "Setup Webhooks",
        content: [
          '<p>1. Login to Paytabs Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.paytabs.sa/merchant/login" target="_blank">https://merchant.paytabs.sa/merchant/login</a>)</p>',
          "<p>2. Select Developers Menu. Go to Configuration section under Payment Notification group.</p>",
          `<p>3. Add new IPN Config Details. Select Type as 'Default Web'. Add URL as <a>https://api.prtest.payorch.com/moyasar/MERCHANT_ID/webhooks</a></p>`,
          "<p>4. Select all the allowed events and save the configuration.</a></p>",
        ],
      },
      {
        title: "Setup PCI DSS Certificates",
        content: [
          "<p>1. Get the Payorch PCI Certification details, ASV reports and Payorch server IP addresses from Payorch team.",
          "<p>2. Contact Paytabs team to enable sending direct raw card details in to their API and whitelist the Payorch IP address.</p>",
        ],
      },
    ],
  },
];
export const ProcessorIcon = ({ processorCode }) => {
  const processor = processors.find(
    (p) => p.displayName?.toLowerCase() === processorCode?.toLowerCase()
  );

  if (processor) {
    console.log(processor);
    return <img src={processor.logo_url} alt={processor.displayName} />;
  }
};
