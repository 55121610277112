import { createSlice } from "@reduxjs/toolkit";

const merchantSlice = createSlice({
  name: "merchant",
  initialState: {
    
    merchantId: null,

  },
  reducers: {
  
    setMerchantId: (state, action) => {
      state.merchantId = action.payload;
    },
   
  },
});

// Action creators are generated for each case reducer function
export const { setMerchantId } =
  merchantSlice.actions;

export default merchantSlice.reducer;
