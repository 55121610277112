import {
  URL,
  MERCHANT_CONFIG_ENDPOINT,
  PROCESSOR_ENDPOINT,
  UPDATE_PROCESSOR_ENDPOINT,
} from "constants/Constants";
import React, { useEffect, useReducer, useState } from "react";
import "./ProcessorSettingCard.css";
import {
  Button,
  TextField,
  Typography,
  Grid,
  Switch,
  Chip,
  Box,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { httpClient } from "utils/HttpClient";
import Checkbox from "@mui/material/Checkbox";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { Dropdown } from "@mui/base/Dropdown";
import { Menu } from "@mui/base/Menu";
import { MenuButton as BaseMenuButton } from "@mui/base/MenuButton";
import { MenuItem as BaseMenuItem, menuItemClasses } from "@mui/base/MenuItem";
import FormControlLabel from "@mui/material/FormControlLabel";
import { CssTransition } from "@mui/base/Transitions";
import { PopupContext } from "@mui/base/Unstable_Popup";
import { styled } from "@mui/material/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import "../../Home/Settings.css";
import { Circle } from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import StepsToIntegrateModal from "../../Home/StepsToIntegrateModal";
import { each } from "lodash";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F5F7FA",
    color: "#525866",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    padding: "8px 12px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: "#0E121B",
    padding: "12px 20px 12px 12px;",
    display: "table-cell",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {},
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Header = ({
  isEdit,
  data,
  onSave,
  setLoading,
  onIntegrateClick,
  onCancel,
  merchantProperties,
  validateFields,
  filterProcessorNames,
  setData,
  profileName,
  setShowNameError,
  showEditNameDisabled,
}) => {
  const [allValuesValid, setAllValuesValid] = useState();
  console.log(data, "new processor data");

  const fetchSettingsData = () => {
    httpClient
      .fetchData(`${URL}${MERCHANT_CONFIG_ENDPOINT}`, "GET")
      .then((data) => {
        // console.log(data.processorProperties, "home page");
        onSave();
        setLoading(false);
      });
  };

  const fetchUpdateData = () => {
    httpClient
      .fetchData(`${URL}${UPDATE_PROCESSOR_ENDPOINT}/${data.id}`, "PUT", data)
      .then((response) => {
        console.log(response, "response saved data");
        fetchSettingsData();
      });
  };

  useEffect(() => {
    const allMandatoryFilled = merchantProperties?.every((property) => {
      const value = data?.processorConfigurations?.[property.name];
      return property.mandatory ? value && value.length > 0 : true;
    });

    setAllValuesValid(allMandatoryFilled);
  }, [data, merchantProperties]);

  // const updateApi = () => {
  //   if (!allValuesValid && profileName === "") {
  //     validateFields();
  //     setShowNameError(true);
  //   } else {
  //     if (profileName === "") {
  //       setShowNameError(true);
  //     }

  //     if (
  //       profileName !== "" &&
  //       !filterProcessorNames?.includes(data?.name?.toLowerCase()?.trim())
  //     ) {
  //       if (!allValuesValid) {
  //         validateFields();
  //       } else {
  //         // console.log("Update Processor Testing");
  //         setLoading(true);
  //         if (data.id === null) {
  //           delete data.id;
  //           httpClient
  //             .fetchData(`${URL}${UPDATE_PROCESSOR_ENDPOINT}`, "POST", data)
  //             .then((response) => {
  //               console.log(response, "res");
  //               fetchSettingsData();
  //             })

  //             .catch((error) => {
  //               console.error("Error Adding :", error);
  //             });
  //         } else {
  //           console.log("testing with selected options");
  //           fetchUpdateData();
  //           // httpClient
  //           //   .fetchData(
  //           //     `${URL}${UPDATE_PROCESSOR_ENDPOINT}/${data.id}`,
  //           //     "PUT",
  //           //     data
  //           //   )
  //           //   .then((response) => {
  //           //     console.log(response, "response saved data");
  //           //     fetchSettingsData();
  //           //   });
  //         }
  //       }
  //     } else if (profileName !== "" && data) {
  //       //if (profileName !== "" && data)
  //       console.log("testing with no options");
  //       if (!allValuesValid) {
  //         validateFields();
  //       } else if (
  //         !filterProcessorNames?.includes(data?.name?.toLowerCase()?.trim())
  //       ) {
  //         console.log("sai");
  //         fetchUpdateData();
  //         // httpClient
  //         //   .fetchData(
  //         //     `${URL}${UPDATE_PROCESSOR_ENDPOINT}/${data.id}`,
  //         //     "PUT",
  //         //     data
  //         //   )
  //         //   .then((response) => {
  //         //     console.log(response, "response saved data");
  //         //     fetchSettingsData();
  //         //   });
  //       } else {
  //         fetchUpdateData();
  //       }
  //     }
  //   }
  // };

  const updateApi = () => {
    console.log("click save button");
    if (!allValuesValid && profileName === "") {
      validateFields();
      setShowNameError(true);
      console.log("all values empty");
    } else if (!allValuesValid && profileName !== "") {
      validateFields();
      console.log("not enter credential data");
    } else if (allValuesValid && profileName === "") {
      setShowNameError(true);
      console.log("name empty");
    } else if (
      allValuesValid &&
      profileName !== "" &&
      !filterProcessorNames?.includes(data?.name?.toLowerCase()?.trim()) &&
      !showEditNameDisabled
    ) {
      console.log("all values are valid");

      setLoading(true);
      if (data.id === null) {
        delete data.id;
        httpClient
          .fetchData(`${URL}${UPDATE_PROCESSOR_ENDPOINT}`, "POST", data)
          .then((response) => {
            console.log(response, "res");
            fetchSettingsData();
            console.log("post created");
          })

          .catch((error) => {
            console.error("Error Adding :", error);
          });
      }
    } else if (allValuesValid && showEditNameDisabled) {
      console.log("edit and update processor");
      fetchUpdateData();
    }
  };

  return (
    <>
      <div className="header-container">
        <div className="new-processor-header-title-container">
          <div className="header-title">
            {isEdit ? "Edit" : "Add"} {data?.processorCode} Payment Processor
          </div>
          <div className="new-processor-header-sub-title">
            Let's {isEdit ? "edit" : "add"} {data?.processorCode} Payment
            processor.
          </div>
        </div>
        <div className="header-buttons-container">
          <Button
            startIcon={<HelpOutlineOutlinedIcon />}
            className="integrate-button"
            variant="outlined"
            onClick={onIntegrateClick}
          >
            Steps to Integrate
          </Button>
          <Button
            variant="outlined"
            className="integrate-button"
            onClick={() => {
              onCancel();
            }}
          >
            Cancel
          </Button>
          <Button
            startIcon={<SaveOutlinedIcon />}
            variant="contained"
            className="save-button"
            onClick={updateApi}
          >
            Save
          </Button>
        </div>
      </div>
    </>
  );
};

const CredsAndProperties = ({
  data,
  setData,
  merchantProperties,
  errors,
  profileName,
  setProfileName,
  filterProcessorNames,
  showEditNameDisabled,
  showNameError,
}) => {
  // const [profileName, setProfileName] = useState("");

  useEffect(() => {
    const googlePayStatus = data?.paymentTypes?.GOOGLE_PAY?.status;
  }, [data]);

  const onChangeValue = (value, key) => {
    setData((prev) => ({
      ...prev,
      processorConfigurations: {
        ...prev.processorConfigurations,
        [key]: value,
      },
    }));
  };

  useEffect(() => {
    const updatedProcessorConfigurations = { ...data.processorConfigurations };

    merchantProperties.forEach((property) => {
      if (property.hidden && property.defaultValue) {
        updatedProcessorConfigurations[property.name] = property.defaultValue;
      }
    });

    setData((prev) => ({
      ...prev,
      name: profileName,
      processorConfigurations: updatedProcessorConfigurations,
    }));
  }, [profileName]);

  return (
    <>
      <Grid container spacing={2} alignItems="center" key={data.id}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",

            width: "100%",
            flexWrap: "wrap",
          }}
        >
          <Box
            sx={{
              display: "flex",
              margin: "20px",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: "50%",
                display: "flex",
                alignItems: "flex-start",
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: "500",
                  width: "100%",
                  textAlign: "left",
                }}
                className="connection-name"
              >
                Connection Name
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <TextField
                sx={{
                  width: "100%",
                  "& .MuiOutlinedInput-root": {
                    height: "40px",
                    "& input": {
                      height: "40px",
                      padding: "0 14px",
                    },
                    "&:hover": {
                      borderColor: "transparent",
                    },
                  },
                  "& .MuiInputLabel-root": {
                    marginTop: "-0.5rem",
                    "&.Mui-focused": {
                      marginTop: "0px",
                    },
                    "&:hover": {
                      borderColor: "transparent",
                    },
                  },

                  // "& fieldset": { border: "none" },
                }}
                // InputLabelProps={{
                //   sx: {
                //     "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                //       border: "2px solid transparent",
                //     },
                //   },
                // }}
                // InputProps={{
                //   sx: {
                //     "& .MuiOutlinedInput-notchedOutline": {
                //       border: "2px solid transparent",

                //     },
                //   },
                // }}
                className={
                  showNameError
                    ? profileName?.length > 0
                      ? "input-field"
                      : "error-name-input-border"
                    : "input-field"
                }
                type="text"
                placeholder="Enter Connection Name"
                onChange={(e) => {
                  setProfileName(e.target.value);
                }}
                value={data?.name}
                error={filterProcessorNames?.includes(
                  data?.name?.toLowerCase()?.trim() || showNameError
                )}
                helperText={
                  filterProcessorNames?.includes(
                    data?.name?.toLowerCase()?.trim()
                  )
                    ? showEditNameDisabled
                      ? ""
                      : "This name is already used try another name"
                    : ""
                }
                disabled={showEditNameDisabled}
              />
              <p
                className={
                  showNameError
                    ? profileName?.length > 0
                      ? "error-name-hide"
                      : "error-name-show"
                    : "error-name-hide"
                }
              >
                Name is required
              </p>
            </Box>
          </Box>
        </Box>
      </Grid>
      <Typography className="creds-title" variant="h6">
        Credentials and Properties
      </Typography>

      <Grid
        container
        spacing={2}
        alignItems="center"
        // sx={{ marginTop: "15px", marginBottom: "48px" }}
      >
        {merchantProperties
          .filter((property) => !property.hidden)
          .map((property, index) => {
            return (
              <>
                <React.Fragment key={index}>
                  <Box
                    key={property.name}
                    sx={{
                      display: "flex",
                      flexDirection: "column",

                      width: "100%",
                      flexWrap: "wrap",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        margin: "10px 20px",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          width: "50%",
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography
                          variant="subtitle1"
                          sx={{
                            fontWeight: "500",
                            width: "100%",
                            textAlign: "left",
                          }}
                          className="connection-name"
                        >
                          {property.displayName}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                        }}
                      >
                        <TextField
                          className="input-field"
                          sx={{
                            width: "100%",
                            "& .MuiOutlinedInput-root": {
                              height: "40px",
                              "& input": {
                                height: "40px",
                                padding: "0 14px",
                              },
                            },
                            "& .MuiInputLabel-root": {
                              marginTop: "-0.5rem",
                              "&.Mui-focused": {
                                marginTop: "0px",
                              },
                            },
                          }}
                          variant="outlined"
                          placeholder={`Enter ${property?.displayName}`}
                          value={
                            data?.processorConfigurations?.[property?.name] ||
                            ""
                          }
                          onChange={(e) => {
                            onChangeValue(e.target.value, property.name);
                          }}
                          error={
                            data?.processorConfigurations?.[property.name]
                              ?.length > 0
                              ? ""
                              : property?.mandatory
                              ? !!errors[property.name]
                              : ""
                          }
                          helperText={
                            data?.processorConfigurations?.[property.name]
                              ?.length > 0
                              ? ""
                              : property?.mandatory
                              ? errors[property.name]
                              : ""
                          }
                        />
                      </Box>
                    </Box>
                  </Box>
                  {/* </Grid> */}
                </React.Fragment>

                {/* <Box key={index}>
                  <Grid item xs={12} sm={4} className="creds-label">
                    <Typography sx={{ fontWeight: "500" }}>
                      {property.displayName}
                    </Typography>
                  
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      value={
                        data?.processorConfigurations?.[property?.name] || ""
                      }
                      onChange={(e) =>
                        onChangeValue(e.target.value, property.name)
                      }
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          height: "40px",
                          "& input": {
                            height: "40px",
                            padding: "0 14px",
                          },
                        },
                        "& .MuiInputLabel-root": {
                          marginTop: "-0.5rem",
                          "&.Mui-focused": {
                            marginTop: "0px",
                          },
                        },
                      }}
                    />
                  </Grid>
                </Box> */}
              </>
            );
          })}

        {/* {data.id !== null ? (
          <>
            <Grid item xs={12} sm={4} className="creds-label">
              <Typography variant="subtitle1" className="text">
                Merchant ID
              </Typography>
              <Typography variant="subtitle2" className="sub-text"></Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextField
                fullWidth
                variant="outlined"
                value={merchantId}
                onChange={(e) => onChangeValue(e.target.value, "merchantId")}
                sx={{
                  width: "100%",
                  "& .MuiOutlinedInput-root": {
                    height: "40px",
                    "& input": {
                      height: "40px",
                      padding: "0 14px",
                    },
                  },
                  "& .MuiInputLabel-root": {
                    marginTop: "-0.5rem",
                    "&.Mui-focused": {
                      marginTop: "0px",
                    },
                  },
                }}
              />
            </Grid>
          </>
        ) : (
          ""
        )}
        <Grid item xs={12} sm={4} className="creds-label">
          <Typography variant="subtitle1" className="text">
            Client ID
          </Typography>
          <Typography variant="subtitle2" className="sub-text">
            Notifications about transactions, balance and exclusive offers.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <TextField
            fullWidth
            variant="outlined"
            onChange={(e) => onChangeValue(e.target.value, "clientKey")}
            value={clientKey}
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                height: "40px",
                "& input": {
                  height: "40px",
                  padding: "0 14px",
                },
              },
              "& .MuiInputLabel-root": {
                marginTop: "-0.5rem",
                "&.Mui-focused": {
                  marginTop: "0px",
                },
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4} className="creds-label">
          <Typography variant="subtitle1" className="text">
            Server Key
          </Typography>
          <Typography variant="subtitle2" className="sub-text">
            Notifications about transactions, balance and exclusive offers.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <TextField
            fullWidth
            variant="outlined"
            value={serverKey}
            onChange={(e) => onChangeValue(e.target.value, "serverKey")}
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                height: "40px",
                "& input": {
                  height: "40px",
                  padding: "0 14px",
                },
              },
              "& .MuiInputLabel-root": {
                marginTop: "-0.5rem",
                "&.Mui-focused": {
                  marginTop: "0px",
                },
              },
            }}
          />
        </Grid> */}
      </Grid>
    </>
  );
};

const Ellipsis = () => (
  <svg
    xmlns="https://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M10.9375 10C10.9375 10.1854 10.8825 10.3667 10.7795 10.5208C10.6765 10.675 10.5301 10.7952 10.3588 10.8661C10.1875 10.9371 9.99896 10.9557 9.8171 10.9195C9.63525 10.8833 9.4682 10.794 9.33709 10.6629C9.20598 10.5318 9.11669 10.3648 9.08051 10.1829C9.04434 10.001 9.06291 9.81254 9.13386 9.64123C9.20482 9.46993 9.32498 9.32351 9.47915 9.2205C9.63332 9.11748 9.81458 9.0625 10 9.0625C10.2486 9.0625 10.4871 9.16127 10.6629 9.33709C10.8387 9.5129 10.9375 9.75136 10.9375 10ZM10 5.625C10.1854 5.625 10.3667 5.57002 10.5208 5.467C10.675 5.36399 10.7952 5.21757 10.8661 5.04627C10.9371 4.87496 10.9557 4.68646 10.9195 4.5046C10.8833 4.32275 10.794 4.1557 10.6629 4.02459C10.5318 3.89348 10.3648 3.80419 10.1829 3.76801C10.001 3.73184 9.81254 3.75041 9.64123 3.82136C9.46993 3.89232 9.32351 4.01248 9.2205 4.16665C9.11748 4.32082 9.0625 4.50208 9.0625 4.6875C9.0625 4.93614 9.16127 5.1746 9.33709 5.35041C9.5129 5.52623 9.75136 5.625 10 5.625ZM10 14.375C9.81458 14.375 9.63332 14.43 9.47915 14.533C9.32498 14.636 9.20482 14.7824 9.13386 14.9537C9.06291 15.125 9.04434 15.3135 9.08051 15.4954C9.11669 15.6773 9.20598 15.8443 9.33709 15.9754C9.4682 16.1065 9.63525 16.1958 9.8171 16.232C9.99896 16.2682 10.1875 16.2496 10.3588 16.1786C10.5301 16.1077 10.6765 15.9875 10.7795 15.8333C10.8825 15.6792 10.9375 15.4979 10.9375 15.3125C10.9375 15.0639 10.8387 14.8254 10.6629 14.6496C10.4871 14.4738 10.2486 14.375 10 14.375Z"
      fill="#0E121B"
    />
  </svg>
);

const blue = {
  50: "#F0F7FF",
  100: "#C2E0FF",
  200: "#99CCF3",
  300: "#66B2FF",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E6",
  700: "#0059B3",
  800: "#004C99",
  900: "#003A75",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const MenuButton = styled(BaseMenuButton)(
  ({ theme }) => `
    display: flex;
    padding: 4px;
    justify-content: center;
    align-items: center;
    gap: 2px;
    border: 0;
    background-color: white;
    `
);

const MenuItem = styled(BaseMenuItem)(
  ({ theme }) => `
    list-style: none;
    padding: 8px;
    border-radius: 8px;
    cursor: default;
    user-select: none;
  
    &:last-of-type {
      border-bottom: none;
    }
  
    &:focus {
      outline: 3px solid ${
        theme.palette.mode === "dark" ? blue[600] : blue[200]
      };
      background-color: ${
        theme.palette.mode === "dark" ? grey[800] : grey[100]
      };
      color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    }
  
    &.${menuItemClasses.disabled} {
      color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
    }
    `
);

const Listbox = styled("ul")(
  ({ theme }) => `
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    box-sizing: border-box;
    padding: 6px;
    margin: 12px 0;
    min-width: 200px;
    border-radius: 12px;
    overflow: auto;
    outline: 0px;
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    box-shadow: 0px 4px 30px ${
      theme.palette.mode === "dark" ? grey[900] : grey[200]
    };
    z-index: 1;
  
    .closed & {
      opacity: 0;
      transform: scale(0.95, 0.8);
      transition: opacity 200ms ease-in, transform 200ms ease-in;
    }
    
    .open & {
      opacity: 1;
      transform: scale(1, 1);
      transition: opacity 100ms ease-out, transform 100ms cubic-bezier(0.43, 0.29, 0.37, 1.48);
    }
  
    .placement-top & {
      transform-origin: bottom;
    }
  
    .placement-bottom & {
      transform-origin: top;
    }
    `
);

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
    "&::before, &::after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
  },
  "& .MuiSwitch-switchBase": {
    transitionDuration: "300ms",
    "&.Mui-checked": {
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#512DD6",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
}));

const ChipView = ({ title, item, setData }) => {
  const handleDelete = () => {
    setData((prev) => ({
      ...prev,
      paymentTypes: {
        ...prev.paymentTypes,
        [item]: {
          ...prev.paymentTypes[item],
          currencies: prev.paymentTypes[item].currencies.filter(
            (currency) => currency !== title
          ),
        },
      },
    }));
  };
  return (
    <Chip
      label={title}
      onDelete={handleDelete}
      sx={{ marginRight: "4px" }}
      className="chip-text"
    />
  );
};

const AnimatedListbox = React.forwardRef(function AnimatedListbox(props, ref) {
  const { ownerState, ...other } = props;
  const popupContext = React.useContext(PopupContext);

  if (popupContext == null) {
    throw new Error(
      "The `AnimatedListbox` component cannot be rendered outside a `Popup` component"
    );
  }

  const verticalPlacement = popupContext.placement.split("-")[0];

  return (
    <CssTransition
      className={`placement-${verticalPlacement}`}
      enterClassName="open"
      exitClassName="closed"
    >
      <Listbox {...other} ref={ref} />
    </CssTransition>
  );
});

const ActionMenu = ({ value, item, setData }) => {
  const [checkBoxValue, setCheckBoxValue] = useState(value);
  const defaultOptionCheckClick = (e) => {
    setCheckBoxValue(e);
    setData((prev) => ({
      ...prev,
      paymentTypes: {
        ...prev.paymentTypes,
        [item]: {
          ...prev.paymentTypes[item],
          defaultOption: e,
        },
      },
    }));
  };

  return (
    <Dropdown>
      <MenuButton>
        <Ellipsis />
      </MenuButton>
      <Menu
        slots={{ listbox: AnimatedListbox }}
        onClick={() => defaultOptionCheckClick(!checkBoxValue)}
      >
        <MenuItem className="settings-title">
          <Checkbox
            sx={{ padding: "0px", paddingRight: "8px" }}
            checked={checkBoxValue}
            onChange={(e) => defaultOptionCheckClick(e.target.checked)}
          />
          Set as Default
        </MenuItem>
      </Menu>
    </Dropdown>
  );
};

const CurrencyMenu = ({ item, setData, data, currencies }) => {
  // console.log(data?.paymentTypes, "currency menu");
  const createHandleMenuClick = (code) => {
    setData((prev) => ({
      ...prev,
      paymentTypes: {
        ...prev.paymentTypes,
        [item]: {
          ...prev.paymentTypes[item],
          currencies: [...prev.paymentTypes[item].currencies, code],
        },
      },
    }));
  };

  const currencyList = currencies.map((currency) => ({
    name: currency,
    code: currency,
  }));

  const sortedData = currencyList.sort((a, b) => {
    return a.name.localeCompare(b.name);
  });

  // console.log(currencyList, "list");

  const checkIfCurrencyExists = (currency) => {
    return data.paymentTypes[item].currencies.includes(currency);
  };
  return (
    <>
      <Dropdown>
        <MenuButton sx={{ padding: "0px", marginRight: "4px" }}>
          <Chip
            label={
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  className="settings-title"
                  sx={{ fontSize: "13px", marginTop: "2px" }}
                >
                  + Add Currency{" "}
                </Typography>
                <KeyboardArrowDownIcon className="settings-title" />
              </Box>
            }
          />
        </MenuButton>
        <Menu slots={{ listbox: AnimatedListbox }}>
          <Typography className="menu-header">ADD CURRENCY </Typography>
          {sortedData.map((currency) => (
            <MenuItem
              disabled={checkIfCurrencyExists(currency.code)}
              onClick={() => createHandleMenuClick(currency.code)}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Circle className="dot-circle" />
              <Typography className="currency-name">
                {currency.name}
              </Typography>{" "}
              <Typography className="currency-code">{currency.code}</Typography>
              {checkIfCurrencyExists(currency.code) ? (
                <CheckIcon sx={{ color: "#525866" }} />
              ) : (
                ""
              )}
            </MenuItem>
          ))}
        </Menu>
      </Dropdown>
    </>
  );
};

const SwitchButton = ({ title, item, setData }) => {
  const [checked, setChecked] = useState(title === "ACTIVE");
  //console.log(item, "item");

  const onChange = (e) => {
    setChecked(e.target.checked);
    setData((prev) => ({
      ...prev,
      paymentTypes: {
        ...prev.paymentTypes,
        [item]: {
          ...prev.paymentTypes[item],
          status: e.target.checked ? "ACTIVE" : "DISABLED",
        },
      },
      // processorConfigurations: {
      //   ...prev.processorConfigurations,
      //   merchantId: "",
      // },
    }));
  };
  return (
    <>
      <FormControlLabel
        control={<Android12Switch checked={checked} onChange={onChange} />} //checked={checked}
      />
    </>
  );
};
const PaymentMethods = ({ data, setData, currencies }) => {
  //console.log(data?.paymentTypes, "paymentMethods");
  return (
    <>
      <Typography className="creds-title" variant="h6">
        Payment Methods
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell className="table-header-text">
                Payment Method
              </StyledTableCell>
              <StyledTableCell className="table-header-text">
                Currencies
              </StyledTableCell>
              <StyledTableCell className="table-header-text">
                Enable
              </StyledTableCell>
              <StyledTableCell></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(data?.paymentTypes)?.map((item, index) => {
              const modified = data?.paymentTypes[item];
              // console.log(modified, "modifiedData");
              const checkingValue =
                modified.currencies &&
                //  modified.currencies.length > 0 &&
                modified.status === "ACTIVE";

              return (
                <StyledTableRow key={index}>
                  <StyledTableCell component="th" scope="row">
                    <Box sx={{ display: "flex" }}>
                      <Typography
                        className="settings-title"
                        sx={{ marginTop: "4px" }}
                      >
                        {item}
                      </Typography>
                      {modified.defaultOption === true ? (
                        <Chip
                          label="Default"
                          sx={{ marginLeft: "4px", marginRight: "4px" }}
                        />
                      ) : (
                        ""
                      )}
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell key={index}>
                    <Box sx={{ display: "flex" }}>
                      {modified.currencies && modified.currencies.length > 0
                        ? modified.currencies?.map((currency) => (
                            <>
                              <ChipView
                                title={currency}
                                item={item}
                                setData={setData}
                              />
                            </>
                          ))
                        : ""}
                      <CurrencyMenu
                        item={item}
                        setData={setData}
                        data={data}
                        currencies={currencies[item]?.currencies || []}
                      />
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell>
                    <SwitchButton
                      title={checkingValue ? "ACTIVE" : "DISABLED"}
                      item={item}
                      setData={setData}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <ActionMenu
                      value={modified.defaultOption}
                      item={item}
                      setData={setData}
                    />
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

function AddEditProcessorSettingCard({
  isEdit,
  data,
  onSave,
  onCancel,
  filterProcessorNames,
  showEditNameDisabled,
}) {
  const [cardData, setCardData] = useState();
  const [showIntegrateModal, setShowIntegrateModal] = React.useState(false);
  const [loading, setLoading] = useState(true);
  const [currencies, setCurrencies] = useState([]);
  const [merchantProperties, setMerchantProperties] = useState([]);
  const [errorMessage, setErrorMessage] = useState({});
  const [errors, setErrors] = useState({});
  const [profileName, setProfileName] = useState(data.name || "");
  const [showNameError, setShowNameError] = useState(false);

  console.log(filterProcessorNames, "filter names data");

  const validateFields = () => {
    const newErrors = {};

    merchantProperties.forEach((property) => {
      if (!property.hidden) {
        const value = data?.processorConfigurations?.[property.name];
        if (!value) {
          newErrors[property.name] = `${property.displayName} is required.`;
        }
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    setLoading(true);
    if (data.id) {
      fetchSettingsData();
    } else {
      setCardData(data);
      setLoading(true);
      fetchCurrencies();
    }
  }, [data]);

  const fetchSettingsData = () => {
    httpClient
      .fetchData(`${URL}${MERCHANT_CONFIG_ENDPOINT}`, "GET")
      .then((response) => {
        // console.log(
        //   response.processorProperties.find((d) => d.id === data.id),
        //   "checkingId"
        // );
        setCardData(response.processorProperties.find((d) => d.id === data.id));
        fetchCurrencies();
      });
  };

  const fetchCurrencies = () => {
    httpClient
      .fetchData(
        `${URL}${PROCESSOR_ENDPOINT}?processorcode=${data?.processorCode}`,
        "GET"
      )
      .then((response) => {
        setCurrencies(response[0].paymentMethods);
        setMerchantProperties(response[0].merchantProperties);
        setLoading(false);
      });
  };

  const googlePayStatus = cardData?.paymentTypes?.GOOGLE_PAY?.status;

  useEffect(() => {
    if (googlePayStatus === "ACTIVE") {
      setMerchantProperties((prevProperties) =>
        prevProperties.map((eachProperty) => {
          if (eachProperty?.name === "merchantId") {
            return { ...eachProperty, mandatory: true };
          }
          return { ...eachProperty };
        })
      );
    } else if (googlePayStatus === "DISABLED") {
      setMerchantProperties((prevProperties) =>
        prevProperties.map((eachProperty) => {
          if (eachProperty?.name === "merchantId") {
            return { ...eachProperty, mandatory: false };
          }
          return { ...eachProperty };
        })
      );
    }
  }, [data, googlePayStatus]);

  return (
    <>
      {loading ? (
        <div className="spinner-container">
          <div className="spinner"></div>
        </div>
      ) : (
        <>
          <Header
            isEdit={isEdit}
            data={cardData}
            onSave={onSave}
            setLoading={setLoading}
            onCancel={onCancel}
            onIntegrateClick={() => setShowIntegrateModal(true)}
            merchantProperties={merchantProperties}
            validateFields={validateFields}
            googlePayStatus={googlePayStatus}
            errors={errors}
            setData={setCardData}
            profileName={profileName}
            setShowNameError={setShowNameError}
            filterProcessorNames={filterProcessorNames}
            showEditNameDisabled={showEditNameDisabled}
          />
          <CredsAndProperties
            data={cardData}
            setData={setCardData}
            merchantProperties={merchantProperties}
            errors={errors}
            googlePayStatus={googlePayStatus}
            setProfileName={setProfileName}
            profileName={profileName}
            filterProcessorNames={filterProcessorNames}
            showEditNameDisabled={showEditNameDisabled}
            showNameError={showNameError}
          />
          <PaymentMethods
            data={cardData}
            setData={setCardData}
            currencies={currencies}
          />
          {showIntegrateModal && (
            <StepsToIntegrateModal
              open={showIntegrateModal}
              setOpen={setShowIntegrateModal}
              processorCode={data?.processorCode}
            />
          )}
        </>
      )}
    </>
  );
}

export default AddEditProcessorSettingCard;
