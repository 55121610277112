import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import {
  FormControl,
  Select,
  TextField,
  Chip,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Alert,
  TableFooter,
  Pagination,
  Tooltip,
  Typography,
  OutlinedInput,
  IconButton,
} from "@mui/material";

import ExpandMoreSharpIcon from "@mui/icons-material/ExpandMoreSharp";
import "react-datetime-range-super-picker/dist/index.css";
import { DNA } from "react-loader-spinner";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { Box } from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import data from "../../utils/TransactionsMap";
import TransactionDetails from "../../components/Elements/Transactions/TransactionDetails";
import CaretDown from "components/Icons/CaretDown";
import XCircleIcon from "components/Icons/XCircle";
import SaveIcon from "components/Icons/FloppyDisk";
import Breadcrumb from "components/Breadcrumb/Breadcrumb";
import {
  URL,
  PAYMENT_TRANSACTIONS_ENDPOINT,
} from "constants/Constants";
import convertFromDate from "utils/ConvertDateToString";
import {
  convertFromTime,
  convertToTime,
  convertToDate,
} from "utils/ConvertDateToString";
import "./Transactions.css";
import paymentTypeIcons from "utils/PaymentTypeIcons";
import processorIcons from "utils/ProcessorIcons";
import { httpClient } from "utils/HttpClient";
import { StyledTableCell, StyledTableRow } from "utils/Styles";
import Logout from "Logout";
import Notepad from "components/Icons/Notepad";
import Search from "components/Icons/Search";
import FunnelSimple from "components/Icons/FunnelSimple";
import ExpandUpdownFill from "components/Icons/ExpandUpdownFill";
import DotsThreeVertical from "components/Icons/DotsThreeVertical";
import { Dropdown } from "@mui/base/Dropdown";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import FileText from "components/Icons/FileText";
import { fil } from "date-fns/locale";
import { CleanHands, Margin } from "@mui/icons-material";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { StaticTimePicker } from "@mui/x-date-pickers/StaticTimePicker";
import { getTime } from "date-fns";
import { FunctionsProvider } from "./FunctionProvider";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import {
  Last7Days,
  Yesterday,
  Today,
  Last30Days,
} from "./ConvertDateToMonthDetails";
import { useMenuFunctions } from "components/Menu/MenuFunctionProvider";
import { useFunctions } from "./FunctionProvider";
import dayjs from "dayjs";
import { filter } from "lodash";

function Transactions() {
  const [transactions, setTransactions] = useState([]);
  const [tableHeadings, setTableHeadings] = useState([]);
  let initialState = [];
  const [filters, setFilters] = useState(initialState);
  const [chipDelete, setChipDelete] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [warning, setWarning] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [clickedChipIndex, setClickedChipIndex] = useState(null);
  const allColumns = Object.keys(data.transactionsMap);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedOptionsMap, setSelectedOptionsMap] = useState([]);
  const [checkedColumns, setCheckedColumns] = useState(
    allColumns.filter((columnKey) =>
      data.ListOfShowingColumns.includes(columnKey)
    )
  );

  // const [checkedColumns, setCheckedColumns] = useState(
  //   [
  //     "merchantRefNumber",
  //     "transactionType",
  //     "id",
  //     "status",
  //     "paymentType",
  //     "amount",
  //   ]?.filter((columnKey) => data.ListOfShowingColumns.includes(columnKey))
  // );

  //console.log(checkedColumns, "columnKey");
  // console.log(allColumns, "all columns");

  const [page, setPage] = useState(1);
  const [dropdownFilter, setDropdownFilter] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [clickedLinkId, setClickedLinkId] = useState();
  const [selectedTransactionType, setSelectedTransactionType] = useState();
  const [breadcrumbList, setBreadcrumbList] = React.useState([
    { title: "Transactions", component: <Notepad /> },
  ]);
  const [fromButtonStatus, setFromButtonStatus] = useState(false);
  const [toButtonStatus, setToButtonStatus] = useState(false);
  const [dateLabel, setDateLabel] = useState(false);
  const [merchantReferenceId, setMerchantReferenceId] = useState(false);
  const [amount, setAmount] = useState(false);
  const [processorReconId, setProcessorReconId] = useState(false);
  const [processorId, setProcessorId] = useState(false);
  const [getDate, setGetDate] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [todayDate, setTodayDate] = useState(false);
  const [yesterdayData, setYesterdayDate] = useState(false);
  const [selectCalenderDate, setSelectCalenderDate] = useState(false);
  const [twoDatesValidation, setTwoDatesValidation] = useState(false);
  // const { merchantId } = UseServiceContext();
  // console.log(merchantId, "merchant");

  const [time, setTime] = useState({
    fromTime: "",
    toTime: "",
  });

  const [date, setDate] = useState([
    {
      fromDate: "",
      toDate: "",
    },
  ]);

  const { toggleExpand } = useMenuFunctions();

  console.log(toggleExpand, "expand");

  // const { todayAndYesterdayDate } = useFunctions();
  // console.log(todayAndYesterdayDate, "today");

  const statusLength = filters?.status?.length;
  const currencyLength = filters?.currency?.length;
  const paymentMethodLength = filters?.paymentMethod?.length;
  const processorCodeLength = filters?.processorCode?.length;
  const transactionTypeLength = filters?.transactionType?.length;

  const initializeTime = () => dayjs().startOf("day");
  const [toTime, setToTime] = useState(initializeTime());

  const handleFromDateChange = (newDate) => {
    setDate((prev) => {
      return { ...prev, fromDate: newDate, fromDateStatus: true };
    });
    setTodayDate(false);
    setYesterdayDate(false);
  };

  const handleFromTimeChange = (newTime, filter) => {
    setTime((prev) => {
      return { ...prev, fromTime: newTime, fromTimeStatus: true };
    });
    handleFilterChange(
      filter?.filterOptions?.queryParamOutbound,
      `${date.fromDate}T${time.fromTime}`
    );
    setTodayDate(false);
    setYesterdayDate(false);
  };

  const handleToDateChange = (newDate) => {
    setDate((prev) => {
      return { ...prev, toDate: newDate, toDateStatus: true };
    });
    setTodayDate(false);
    setYesterdayDate(false);
  };

  const handleToTimeChange = (newTime, filter) => {
    setTime((prev) => {
      return { ...prev, toTime: newTime, toTimeStatus: true };
    });
    handleFilterChange(
      filter?.filterOptions?.queryParamInbound,
      `${date.toDate}T${time.toTime}`
    );
    setTodayDate(false);
    setYesterdayDate(false);
  };

  const handleLinkClick = (id, type) => {
    // console.log(type);
    setSelectedTransactionType(type);
    // console.log(selectedTransactionType, "type in transactions");
    setClickedLinkId(id);
  };

  console.log(filters, "filters");
  console.log(selectedOptionsMap, "map");
  console.log(transactions, "transactions");

  useEffect(() => {
    if (filters.transactionTime_from && filters.transactionTime_to) {
      setTwoDatesValidation(true);
    }
  }, [filters.transactionTime_from, filters.transactionTime_to]);

  useEffect(() => {
    if (filters?.merchantReferenceId?.length > 0) {
      setMerchantReferenceId(true);
    } else {
      setMerchantReferenceId(false);
    }

    if (filters?.processorReconId?.length > 0) {
      setProcessorReconId(true);
    } else {
      setProcessorReconId(false);
    }

    if (filters?.processorTransactionId?.length > 0) {
      setProcessorId(true);
    } else {
      setProcessorId(false);
    }
  });
  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(1);
  //   fetchTransactionDataWithFilter();
  // };

  // const fromDate = (event) => {
  //   //setDate(event.target.value);
  //   setFilters((previousState) => {
  //     return { ...previousState, fromDate: event.target.value };
  //   });
  // };

  // const toDate = (event) => {
  //   setFilters((previousState) => {
  //     return { ...previousState, toDate: event.target.value };
  //   });
  // };

  // const reloadFilters = () => {
  //   setWarning(false);

  //   fetchTransactionDataWithFilter();
  // };

  const applyfilters = () => {
    console.log("apply");
    handleClose();
  };

  const handleDetailsClose = () => {
    console.log("close");
    setClickedLinkId(null);
    setSelectedTransactionType(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const hasFilters = (filter) =>
    filters[filter.filterOptions.queryParam]?.length ||
    (filters[filter.filterOptions.queryParamInbound]?.length &&
      filters[filter.filterOptions.queryParamOutbound]?.length);

  Object.keys(data.transactionsMap).forEach((key) => {
    const selectedOptions =
      data.transactionsMap[key].filterOptions.selectedOptions;
    initialState = {
      ...initialState,
      [key]: selectedOptions,
    };
  });
  const renderTableCell = (item, columnKey, data) => {
    const { transactionsMap } = data;
    const columnData = transactionsMap[columnKey];
    const path = columnData.path;

    if (path === "paymentMethod" || path === "processorCode") {
      const value = getFirstNonNullValue(item, path);

      return (
        <Tooltip key={value} title={value}>
          {value && (
            <img
              style={{ height: 48, width: 48 }}
              src={paymentTypeIcons[value] || processorIcons[value] || null}
            />
          )}
        </Tooltip>
      );
    } else if (path === "merchantReferenceId") {
      return (
        <Typography>
          <a
            href="#"
            className={`reference-link settings-title`}
            onClick={() => handleLinkClick(item.id, item.transactionType)}
          >
            {item.merchantReferenceId}
          </a>
        </Typography>
      );
    } else {
      return getFirstNonNullValue(item, path);
    }
  };

  useEffect(() => {
    fetchTransactionDataWithFilter();
  }, [chipDelete, page]);
  useLayoutEffect(() => {
    fetchTransactionData();
  }, []);
  const fetchTransactionData = () => {
    setPage(1);
    const formData = new URLSearchParams();

    formData.append(
      "transactionType",
      "PAYMENT, CAPTURE, REFUND, CREDIT, CHECKOUT_SESSION"
    );

    const response = httpClient
      .postFormData(
        `${URL}${PAYMENT_TRANSACTIONS_ENDPOINT}?&type=transactions&orderbycol=transactionTime&orderby=DESC&pageNumber=${page}&pageSize=${rowsPerPage}`,

        formData
      )
      .then((response) => {
        // Check if the status code is 200
        if (response.ok) {
          return response.json();
        } else {
          // Check if the status code is 401
          if (response.status === 401) {
            Logout();
          } else {
            setWarning(true);
          }
        }
      })
      .then((responseData) => {
        // console.log(responseData);
        setTransactions(responseData);
        if (responseData.length > 0) {
          // Assuming the first item in the response array contains all table headings
          setTableHeadings(Object.keys(responseData[0]));
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle errors here
      });
  };

  const fetchTransactionDataWithFilter = (pageNumber, pageSize) => {
    //  console.log("in http call ", filters);

    setIsLoading(true);

    var formData = new URLSearchParams({
      ...Object.fromEntries(
        Object.entries(filters)?.filter(([key, value]) => {
          return Array.isArray(value)
            ? value.some((item) => item !== null)
            : value !== null;
        })
      ),
    });

    if (formData.toString().length === 0) {
      // console.log("in length = 0");
      formData = new URLSearchParams();
      formData.append(
        "transactionType",
        "PAYMENT, CAPTURE, REFUND, CREDIT, CHECKOUT_SESSION"
      );
    }
    // console.log(formData, "formData");

    httpClient
      .postFormData(
        `${URL}${PAYMENT_TRANSACTIONS_ENDPOINT}?&type=transactions&orderbycol=transactionTime&orderby=DESC&pageNumber=${page}&pageSize=${rowsPerPage}`,
        formData
      )
      .then((response) => {
        // Check if the status code is 200
        if (response.ok) {
          return response.json();
        } else {
          // Check if the status code is 401
          if (response.status === 401) {
            Logout();
          } else {
            setWarning(true);
          }
        }
      })
      .then((responseData) => {
        // console.log(responseData, "page:", page);
        setIsLoading(false);
        setTransactions(responseData);
        if (responseData?.length > 0) {
          // Assuming the first item in the response array contains all table headings
          setTableHeadings(Object.keys(responseData[0]));
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle errors here
      });
  };

  const handleFilterChange = (type, param) => {
    console.log(type, param, "amount data");
    // const handleOkClick = () => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [type]: param,
    }));
    //}

    setSelectedOptionsMap((prevFilters) => ({
      ...prevFilters,
      [type]: param,
    }));
  };

  // useEffect(() => {
  //   reloadFilters();
  // }, [
  //   filters?.merchantReferenceId,
  //   filters?.processorReconId,
  //   filters?.processorTransactionId,
  // ]);

  //in Search-bar Table Data Searching

  let searchText = filters?.searchQuery?.toString() || "";

  const filterData = transactions?.filter((each) => {
    // console.log(each, "each");

    let searchAmount = each?.amount?.value?.toString()?.includes(searchText);

    let searchTransactionType = each?.transactionType
      ?.toLowerCase()
      ?.includes(searchText);

    let searchStatus = each?.status.toLowerCase().includes(searchText);

    let searchCurrencyCode = each?.amount?.currencyCode
      ?.toLowerCase()
      .includes(searchText);

    let searchMerchantReferenceId = each?.merchantReferenceId
      ?.toLowerCase()
      ?.includes(searchText);
    let searchId = each?.id?.toLowerCase()?.includes(searchText);

    let searchDate = each?.expiresAt?.toLowerCase()?.includes(searchText);
    let searchProcessorId = each?.processorReconId?.includes(searchText);
    let searchProcessorTransactionId =
      each?.processorTransactionId?.includes(searchText);

    return searchText === ""
      ? each
      : searchAmount ||
          searchTransactionType ||
          searchStatus ||
          searchCurrencyCode ||
          searchMerchantReferenceId ||
          searchId ||
          searchProcessorId ||
          searchProcessorTransactionId ||
          searchDate;
  });

  const reloadFilters = () => {
    setWarning(false);
    fetchTransactionDataWithFilter();
  };

  const resetFilters = (filter, queryParamInbound, queryParamOutbound) => {
    setChipDelete((prevValue) => !prevValue);

    if (filter) {
      // Reset specific filter

      setFilters((prevFilters) => ({
        ...prevFilters,
        [filter]: Array.isArray(filter)
          ? []
          : typeof filter === "string"
          ? null
          : { [queryParamInbound]: null, [queryParamOutbound]: null },
      }));
      if (Array.isArray(filters[filter])) {
        setSelectedOptionsMap((prevSelectedOptionsMap) => ({
          ...prevSelectedOptionsMap,
          [filter]: [],
        }));
      }
      console.log("filters ", filters);
    } else {
      // Reset all filters
      setPage(1);
      setFilters([]);
      applyfilters();
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setClickedChipIndex(null);
    setTwoDatesValidation(false);
  };

  const handleChipDelete = (
    queryParam,
    queryParamInbound,
    queryParamOutbound
  ) => {
    resetFilters(queryParam, queryParamInbound, queryParamOutbound);

    handleClose();
  };

  const handleCheckboxChange = (columnKey) => (event) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setCheckedColumns([...checkedColumns, columnKey]);
      if (!data.ListOfShowingColumns.includes(columnKey)) {
        data.ListOfShowingColumns.push(columnKey);
      }
      data.ListOfShowingColumns.sort(
        (a, b) => data.transactionsMap[a].order - data.transactionsMap[b].order
      );
    } else {
      setCheckedColumns(checkedColumns.filter((col) => col !== columnKey));
      data.ListOfShowingColumns = data.ListOfShowingColumns.filter(
        (col) => col !== columnKey
      );
      data.ListOfShowingColumns.sort(
        (a, b) => data.transactionsMap[a].order - data.transactionsMap[b].order
      );
    }
  };

  const handleFilterCheckboxChange = (filterParam, option) => {
    setSelectedOptionsMap((prevSelectedOptionsMap) => {
      const selectedOptions = prevSelectedOptionsMap[filterParam] || [];
      const updatedOptions = selectedOptions.includes(option)
        ? selectedOptions.filter((opt) => opt !== option)
        : [...selectedOptions, option];
      handleFilterChange(filterParam, updatedOptions);
      return {
        ...prevSelectedOptionsMap,
        [filterParam]: updatedOptions,
      };
    });

    // setSelectedOptionsMap((prevSelectedOptionsMap) => {
    //   const selectedOptions = prevSelectedOptionsMap[filterParam] || [];
    //   const updatedOptions = selectedOptions.includes(option)
    //     ? selectedOptions.filter((opt) => opt !== option)
    //     : [...selectedOptions, option];
    //   handleFilterChange(filterParam, updatedOptions);
    //   return {
    //     ...prevSelectedOptionsMap,
    //     [filterParam]: updatedOptions,
    //   };
    // });
  };

  // Select All Option
  const handleSelectAllOptions = (param, optionsList) => {
    let selectedData;
    Object.keys(data.transactionsMap).filter((paramData) => {
      if (data.transactionsMap[paramData].path === param) {
        selectedData = paramData;
      } else if (data.transactionsMap[paramData].path.slice(7, 15) === param) {
        selectedData = paramData;
      }
    });

    const selectedParamOptionsLength =
      data.transactionsMap[selectedData]?.filterOptions.options.length;
    if (filters[param]?.length === selectedParamOptionsLength) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        [param]: "",
      }));
      setSelectedOptionsMap((prevFilters) => ({
        ...prevFilters,
        [param]: "",
      }));
    } else {
      handleFilterChange(param, optionsList);
    }
  };

  const clickFilters = () => {
    setDropdownFilter(!dropdownFilter);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      reloadFilters();
    }
  };

  useEffect(() => {
    if (filters.text === "") {
      reloadFilters();
    }
  }, [filters.text?.length === 0]);

  return (
    <FunctionsProvider>
      <div
        className={
          toggleExpand
            ? "select-payment-transaction-container"
            : "payment-transaction-container"
        }
      >
        {!clickedLinkId ? (
          <div className={"transaction-main-container"}>
            <Box className="transaction-header">
              <div className="breadcrumb-containers">
                <Breadcrumb list={breadcrumbList} />
              </div>
              <h1 className="transaction-text">Transactions</h1>
              {/* <TextField  /> */}
              <Typography variant="h6" className="description-text">
                Initiate, track and manage all your transactions at one place.
              </Typography>
            </Box>

            <Box
              className={
                toggleExpand
                  ? "expanded-transaction-date-row-container"
                  : "transaction-date-row-container"
              }
            >
              <Box className="filter-frame-container">
                {Object.values(data.transactionsMap).map((filter, index) => {
                  return (
                    filter.filterOptions.type === "DATE" && (
                      <Box
                        key={filter.displayName}
                        className="calendar-container"
                      >
                        <Box className="drop-down-container">
                          <div
                            className="click-dropdown"
                            onClick={(event) =>
                              setAnchorEl(
                                { index, currentTarget: event.currentTarget },
                                setClickedChipIndex(index),
                                setSearchTerm(""),
                                setSelectCalenderDate(false)
                              )
                            }
                            onDelete={
                              hasFilters
                                ? () =>
                                    handleChipDelete(
                                      filter.filterOptions.queryParam,
                                      filter.filterOptions.queryParamInbound,
                                      filter.filterOptions.queryParamOutbound
                                    )
                                : null
                            }
                          >
                            <Typography className="placehoder-text">
                              {/* {
                                filter.displayName === "Date"
                                  ? getDate
                                    ? todayDate || yesterdayData
                                      ? filters.transactionTime_from?.slice(
                                          0,
                                          10
                                        )
                                      : `${filters?.transactionTime_from?.slice(
                                          0,
                                          10
                                        )} To ${filters?.transactionTime_to?.slice(
                                          0,
                                          10
                                        )}`
                                    : `${filter.displayName}`
                                  : filter.displayName === "Date" &&
                                    `${filter.displayName}`} */}
                              {filter.displayName === "Date" && getDate
                                ? todayDate || yesterdayData
                                  ? filters.transactionTime_from?.slice(0, 10)
                                  : `${filters?.transactionTime_from?.slice(
                                      0,
                                      10
                                    )} To ${filters?.transactionTime_to?.slice(
                                      0,
                                      10
                                    )}`
                                : selectCalenderDate &&
                                  filter.displayName === "Date"
                                ? `${filters?.transactionTime_from?.slice(
                                    0,
                                    10
                                  )} To ${filters?.transactionTime_to?.slice(
                                    0,
                                    10
                                  )}`
                                : filter.displayName === "Date" &&
                                  `${filter.displayName}`}
                            </Typography>

                            <CaretDown className="cart-down-icon" />
                          </div>
                        </Box>

                        <Menu
                          className="pop-up-alignment"
                          anchorEl={
                            anchorEl && anchorEl.index === index
                              ? anchorEl.currentTarget
                              : null
                          }
                          open={Boolean(anchorEl && anchorEl.index === index)}
                          onClose={() => {
                            setAnchorEl(null);
                            setClickedChipIndex(null);
                            setFromButtonStatus(false);
                            setToButtonStatus(false);
                            setDate((prev) => ({
                              ...prev,
                              fromDate: "",
                              toDate: "",
                            }));
                            setTime((prev) => ({
                              ...prev,
                              fromTime: "",
                              toTime: "",
                            }));
                          }}
                        >
                          {filter.filterOptions.type === "DATE" && (
                            <div style={{ padding: "0px 10px" }}>
                              <FunctionsProvider>
                                <Box
                                  sx={{
                                    display: "flex",
                                    gap: 2,
                                    flexWrap: "wrap",
                                    overflowX: "scroll",
                                    width: "100%",
                                  }}
                                  className="buttons-row-container"
                                >
                                  <Today
                                    getDate={setGetDate}
                                    filter={filter}
                                    handleFilterChange={handleFilterChange}
                                    fetchTransactionDataWithFilter={
                                      reloadFilters
                                    }
                                    handleClose={handleClose}
                                    setDateLabel={setDateLabel}
                                    setTodayDate={setTodayDate}
                                    setYesterdayDate={setYesterdayDate}
                                  />
                                  <Yesterday
                                    getDate={setGetDate}
                                    filter={filter}
                                    handleFilterChange={handleFilterChange}
                                    fetchTransactionDataWithFilter={
                                      reloadFilters
                                    }
                                    handleClose={handleClose}
                                    setDateLabel={setDateLabel}
                                    setTodayDate={setTodayDate}
                                    setYesterdayDate={setYesterdayDate}
                                  />
                                  <Last7Days
                                    getDate={setGetDate}
                                    filter={filter}
                                    handleFilterChange={handleFilterChange}
                                    fetchTransactionDataWithFilter={
                                      reloadFilters
                                    }
                                    handleClose={handleClose}
                                    setDateLabel={setDateLabel}
                                    setTodayDate={setTodayDate}
                                    setYesterdayDate={setYesterdayDate}
                                  />
                                  <Last30Days
                                    getDate={setGetDate}
                                    filter={filter}
                                    handleFilterChange={handleFilterChange}
                                    fetchTransactionDataWithFilter={
                                      reloadFilters
                                    }
                                    handleClose={handleClose}
                                    setDateLabel={setDateLabel}
                                    setTodayDate={setTodayDate}
                                    setYesterdayDate={setYesterdayDate}
                                  />
                                </Box>
                              </FunctionsProvider>
                              <div style={{ display: "flex" }}>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DemoContainer
                                    components={["DatePicker", "TimeClock"]}
                                  >
                                    <Box sx={{ display: "flex", gap: "10px" }}>
                                      <DemoItem>
                                        <h3 style={{ fontSize: "15px" }}>
                                          From Date
                                        </h3>
                                        <DatePicker
                                          className="date-field"
                                          sx={{
                                            height: "40px",
                                            "& .MuiInputBase-root": {
                                              height: "100%",
                                            },
                                          }}
                                          onChange={(newFromDate) =>
                                            handleFromDateChange(
                                              convertFromDate(newFromDate.$d)
                                            )
                                          }
                                          renderInput={(params) => (
                                            <TextField {...params} />
                                          )}
                                        />

                                        <DemoItem>
                                          <StaticTimePicker
                                            onChange={(time) => {
                                              handleFromTimeChange(
                                                convertFromTime(time),
                                                filter
                                              );
                                            }}
                                            value={toTime}
                                          />
                                        </DemoItem>
                                      </DemoItem>
                                      <DemoItem>
                                        <h3 style={{ fontSize: "15px" }}>
                                          To Date
                                        </h3>
                                        <DatePicker
                                          sx={{
                                            height: "40px",
                                            "& .MuiInputBase-root": {
                                              height: "100%",
                                            },
                                          }}
                                          className="date-field"
                                          onChange={(newFromDate) =>
                                            handleToDateChange(
                                              convertToDate(newFromDate.$d)
                                            )
                                          }
                                          renderInput={(params) => (
                                            <TextField {...params} />
                                          )}
                                        />

                                        <DemoItem>
                                          <StaticTimePicker
                                            onChange={(time) => {
                                              handleToTimeChange(
                                                convertToTime(time),
                                                filter
                                              );
                                            }}
                                            value={toTime}
                                          />
                                        </DemoItem>
                                      </DemoItem>
                                    </Box>
                                  </DemoContainer>
                                </LocalizationProvider>
                              </div>
                            </div>
                          )}

                          {filter.filterOptions.type !== "DATE" && (
                            <MenuItem className="drop-down-menu-item">
                              {/* Render the specific UI for other types of filters */}
                              <TextField
                                size="small"
                                fullWidth
                                label={filter.displayName}
                                placeholder={`Enter ${filter.displayName}`}
                                value={filters[filter.filterOptions.queryParam]}
                                onChange={(e) =>
                                  handleFilterChange(
                                    filter.filterOptions.queryParam,
                                    e.target.value
                                  )
                                }
                              />
                            </MenuItem>
                          )}
                          <MenuItem
                            sx={{
                              display: "flex",
                              gap: "10px",
                              justifyContent: "space-between",
                            }}
                          >
                            <Button
                              size="small"
                              variant="outlined"
                              color="inherit"
                              onClick={() => {
                                if (filter.displayName === "Date") {
                                  setDate((prev) => ({
                                    ...prev,
                                    fromDate: "",
                                    toDate: "",
                                  }));
                                  setTime((prev) => ({
                                    ...prev,
                                    fromTime: "",
                                    toTime: "",
                                  }));

                                  delete filters.transactionTime_from;
                                  delete filters.transactionTime_to;

                                  handleClose();
                                } else if (filter.displayName !== "Date") {
                                  resetFilters(filter.filterOptions.queryParam);
                                  // resetData();
                                  setFromButtonStatus(false);
                                  setToButtonStatus(false);

                                  setDateLabel(false);
                                  setGetDate(false);
                                  handleClose();

                                  filter.displayName ===
                                    "Merchant Reference Id" &&
                                    setMerchantReferenceId(false);
                                  filter.displayName === "Amount" &&
                                    setAmount(false);
                                  filter.displayName === "Date" &&
                                    setDateLabel(false);
                                  filter.displayName === "Processor Recon Id" &&
                                    setProcessorReconId(false);
                                  filter.displayName === "Processor Id" &&
                                    setProcessorId(false);
                                }
                              }}
                            >
                              Cancel
                            </Button>
                            <Button
                              size="small"
                              variant="outlined"
                              color="inherit"
                              onClick={() => {
                                if (twoDatesValidation) {
                                  applyfilters(filter);
                                  setFromButtonStatus(false);
                                  setToButtonStatus(false);
                                  setDate((prev) => ({
                                    ...prev,
                                    fromDate: "",
                                    toDate: "",
                                  }));
                                  setTime((prev) => ({
                                    ...prev,
                                    fromTime: "",
                                    toTime: "",
                                  }));
                                  reloadFilters();
                                  filter.displayName ===
                                    "Merchant Reference Id" &&
                                    setMerchantReferenceId(true);
                                  filter.displayName === "Amount" &&
                                    setAmount(true);
                                  // filter.displayName === "Date" &&
                                  //   setDateLabel(true);
                                  filter.displayName === "Processor Recon Id" &&
                                    setProcessorReconId(true);
                                  filter.displayName === "Processor Id" &&
                                    setProcessorId(true);
                                  setSelectCalenderDate(true);
                                } else if (filter.displayName !== "Date") {
                                  applyfilters(filter);
                                  setFromButtonStatus(false);
                                  setToButtonStatus(false);

                                  reloadFilters();
                                  filter.displayName ===
                                    "Merchant Reference Id" &&
                                    setMerchantReferenceId(true);
                                  filter.displayName === "Amount" &&
                                    setAmount(true);
                                  // filter.displayName === "Date" &&
                                  //   setDateLabel(true);
                                  filter.displayName === "Processor Recon Id" &&
                                    setProcessorReconId(true);
                                  filter.displayName === "Processor Id" &&
                                    setProcessorId(true);
                                  //setSelectCalenderDate(true);
                                }
                              }}
                            >
                              Ok
                            </Button>

                            {/* <Button
                                size="small"
                                variant="outlined"
                                color="inherit"
                                onClick={() => {
                                  resetFilters(filter.filterOptions.queryParam);
                                  // resetData();
                                  setFromButtonStatus(false);
                                  setToButtonStatus(false);
                                  setDate((prev) => ({
                                    ...prev,
                                    fromDate: "",
                                    toDate: "",
                                  }));
                                  setTime((prev) => ({
                                    ...prev,
                                    fromTime: "",
                                    toTime: "",
                                  }));
                                  setFilters([]);
                                  filter.displayName ===
                                    "Merchant Reference Id" &&
                                    setMerchantReferenceId(false);
                                  filter.displayName === "Amount" &&
                                    setAmount(false);
                                }}
                              >
                                Cancel
                              </Button>
                              <Button
                                size="small"
                                variant="outlined"
                                color="inherit"
                                onClick={() => {
                                  if (twoDatesValidation) {
                                    applyfilters(filter);
                                    setFromButtonStatus(false);
                                    setToButtonStatus(false);

                                    setDate((prev) => ({
                                      ...prev,
                                      fromDate: "",
                                      toDate: "",
                                    }));
                                    setTime((prev) => ({
                                      ...prev,
                                      fromTime: "",
                                      toTime: "",
                                    }));
                                    reloadFilters();

                                    filter.displayName ===
                                      "Merchant Reference Id" &&
                                      setMerchantReferenceId(true);
                                    filter.displayName === "Amount" &&
                                      setAmount(true);
                                    setSelectCalenderDate(true);
                                  }
                                }}
                              >
                                Ok
                              </Button> */}
                          </MenuItem>
                        </Menu>
                      </Box>
                    )
                  );
                })}
              </Box>

              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  marginBottom: "10px",
                }}
              >
                <Box className="search-container">
                  <Search />
                  <OutlinedInput
                    size="small"
                    placeholder="Search"
                    type="search"
                    className="search-input-field"
                    sx={{
                      width: "100%",
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                    }}
                    // value={filters.searchQuery}
                    value={filters.text}
                    onChange={(e) => {
                      handleFilterChange("text", e.target.value);
                      setSearchValue(e.target.value);
                    }}
                    onKeyDown={handleKeyDown}
                  />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                  className="filter-reload-container"
                >
                  <Tooltip title={dropdownFilter ? "Close" : "Open"}>
                    <Box className="filter-button" onClick={clickFilters}>
                      <FunnelSimple />
                      <Typography sx={{ color: "white", fontSize: "15px" }}>
                        Filters
                      </Typography>
                      <ExpandMoreSharpIcon style={{ color: "white" }} />
                    </Box>
                  </Tooltip>
                  <Tooltip title="Reload">
                    <Button
                      className="reload-button"
                      size="small"
                      variant="outlined"
                      onClick={reloadFilters}
                    >
                      <RestartAltIcon></RestartAltIcon>Reload
                    </Button>
                  </Tooltip>
                </Box>
              </div>
            </Box>

            {dropdownFilter && (
              <div
                className={
                  toggleExpand
                    ? "expanded-filter-container-details"
                    : "filter-container-details"
                }
              >
                <Typography variant="paragraph" className="filters-text">
                  Filters
                </Typography>
                <Box className="filter-frame-container">
                  {Object.values(data.transactionsMap).map((filter, index) => {
                    // console.log(filter, "filter Details");

                    const paymentTypePopupHeight =
                      filter.displayName === "Payment Type"
                        ? "payment-type-popup-height"
                        : filter.displayName === "Processor Code"
                        ? "payment-type-popup-height"
                        : "drop-down-menu-item";

                    return filter.filtered &&
                      filter.filterOptions.type === "STRING" ? (
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography
                          sx={{ display: "flex", alignItems: "flex-start" }}
                        >
                          {filter.displayName === "Merchant Reference Id" && (
                            <span
                              className={
                                merchantReferenceId
                                  ? "filter-label-text-bold"
                                  : "filter-label-text"
                              }
                            >
                              {filter.displayName}
                            </span>
                          )}
                          {filter.displayName === "Processor Recon Id" && (
                            <span
                              className={
                                processorReconId
                                  ? "filter-label-text-bold"
                                  : "filter-label-text"
                              }
                            >
                              {filter.displayName}
                            </span>
                          )}
                          {filter.displayName === "Processor Id" && (
                            <span
                              className={
                                processorId
                                  ? "filter-label-text-bold"
                                  : "filter-label-text"
                              }
                            >
                              {filter.displayName}
                            </span>
                          )}
                        </Typography>
                        <Box
                          key={filter.displayName}
                          className="each-filter-frame"
                        >
                          <TextField
                            type="search"
                            className="input-search-field"
                            sx={{
                              // background: "plum",
                              borderRadius: "9px",
                              "& .MuiOutlinedInput-root": {
                                borderRadius: "9px",
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                  borderColor: "#cacfd8",
                                },
                                "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                  {
                                    borderColor: "#cacfd8",
                                  },
                              },
                            }}
                            InputLabelProps={{
                              sx: {
                                color: " #717784",
                                fontSize: "14px",
                                fontFamily: "Inter",
                                fontWeight: "400",
                                "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                  {
                                    color: " #717784",
                                  },
                              },
                            }}
                            InputProps={{
                              sx: {
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: "#cacfd8",
                                  borderRadius: "9px",
                                },
                              },
                            }}
                            size="small"
                            fullWidth
                            label={filter.displayName}
                            placeholder={`Enter ${filter.displayName}`}
                            value={filters[filter.filterOptions.queryParam]}
                            onChange={(e) =>
                              handleFilterChange(
                                filter.filterOptions.queryParam,
                                e.target.value
                              )
                            }
                          />
                        </Box>
                      </Box>
                    ) : filter.filtered === false ? (
                      ""
                    ) : (
                      <Box
                        key={filter.displayName}
                        className={
                          filter.displayName !== "Date"
                            ? "each-filter-frame"
                            : "hide-date-frame"
                        }
                      >
                        <Typography>
                          {/* {filter.displayName === "Merchant Reference Id" && (
                            <span
                              className={
                                merchantReferenceId
                                  ? "filter-label-text-bold"
                                  : "filter-label-text"
                              }
                            >
                              {filter.displayName}
                            </span>
                          )} */}

                          {/* {filter.displayName === "Processor Recon Id" && (
                            <span
                              className={
                                processorReconId
                                  ? "filter-label-text-bold"
                                  : "filter-label-text"
                              }
                            >
                              {filter.displayName}
                            </span>
                          )} */}

                          {/* {filter.displayName === "Processor Id" && (
                            <span
                              className={
                                processorId
                                  ? "filter-label-text-bold"
                                  : "filter-label-text"
                              }
                            >
                              {filter.displayName}
                            </span>
                          )} */}

                          {filter.displayName === "Status" && (
                            <span
                              className={
                                filters?.status?.length > 0
                                  ? "filter-label-text-bold"
                                  : "filter-label-text"
                              }
                            >{`${filter.displayName}${
                              filters[filter.filterOptions.queryParam]
                                ?.length && filter.filterOptions.options
                                ? `: ${
                                    filters[filter.filterOptions.queryParam]
                                      .length
                                  } of ${filter.filterOptions.options.length}`
                                : ""
                            }`}</span>
                          )}

                          {filter.filterOptions.queryParam ===
                            "paymentMethod" && (
                            <span
                              className={
                                filters?.paymentMethod?.length > 0
                                  ? "filter-label-text-bold"
                                  : "filter-label-text"
                              }
                            >{`${filter.displayName}${
                              filters[filter.filterOptions.queryParam]
                                ?.length && filter.filterOptions.options
                                ? `: ${
                                    filters[filter.filterOptions.queryParam]
                                      .length
                                  } of ${filter.filterOptions.options.length}`
                                : ""
                            }`}</span>
                          )}

                          {filter.filterOptions.queryParam ===
                            "processorCode" && (
                            <span
                              className={
                                filters?.processorCode?.length > 0
                                  ? "filter-label-text-bold"
                                  : "filter-label-text"
                              }
                            >{`${filter.displayName}${
                              filters[filter.filterOptions.queryParam]
                                ?.length && filter.filterOptions.options
                                ? `: ${
                                    filters[filter.filterOptions.queryParam]
                                      .length
                                  } of ${filter.filterOptions.options.length}`
                                : ""
                            }`}</span>
                          )}

                          {filter.displayName === "Currency Code" && (
                            <span
                              className={
                                filters?.currency?.length > 0
                                  ? "filter-label-text-bold"
                                  : "filter-label-text"
                              }
                            >{`${filter.displayName}${
                              filters[filter.filterOptions.queryParam]
                                ?.length && filter.filterOptions.options
                                ? `: ${
                                    filters[filter.filterOptions.queryParam]
                                      .length
                                  } of ${filter.filterOptions.options.length}`
                                : ""
                            }`}</span>
                          )}

                          {filter.displayName === "Transaction Type" && (
                            <span
                              className={
                                filters?.transactionType?.length > 0
                                  ? "filter-label-text-bold"
                                  : "filter-label-text"
                              }
                            >{`${filter.displayName}${
                              filters[filter.filterOptions.queryParam]
                                ?.length && filter.filterOptions.options
                                ? `: ${
                                    filters[filter.filterOptions.queryParam]
                                      .length
                                  } of ${filter.filterOptions.options.length}`
                                : ""
                            }`}</span>
                          )}

                          {filter.displayName === "Amount" && (
                            <span
                              className={
                                amount
                                  ? "filter-label-text-bold"
                                  : "filter-label-text"
                              }
                            >
                              {filter.displayName}
                              {filters?.amount_to?.length > 0 &&
                              filters?.amount_from.length > 0
                                ? ` : ${filters?.amount_from} To ${filters?.amount_to}`
                                : ""}
                            </span>
                          )}

                          {/* {filter.displayName === "Date" && (
                            <span
                              className={
                                // dateLabel
                                filters?.transactionTime_from?.length > 0
                                  ? "filter-label-text-bold"
                                  : "filter-label-text"
                              }
                            >
                              {filter.displayName}

                              {
                                // dateLabel &&
                                // dateLabel &&
                                filters?.transactionTime_from?.length > 0 &&
                                filters.transactionTime_from &&
                                filters.transactionTime_to
                                  ? todayDate || yesterdayData
                                    ? ` : ${filters.transactionTime_from?.slice(
                                        0,
                                        10
                                      )}`
                                    : ` : ${filters?.transactionTime_from?.slice(
                                        0,
                                        10
                                      )} To ${filters?.transactionTime_to?.slice(
                                        0,
                                        10
                                      )}`
                                  : // `: (${filters?.transactionTime_from?.slice(
                                    //     0,
                                    //     10
                                    //   )} To ${filters?.transactionTime_to?.slice(
                                    //     0,
                                    //     10
                                    //   )})`

                                    ""
                              }
                            </span>
                          )} */}
                        </Typography>
                        <Box className="drop-down-container">
                          <div
                            className="click-dropdown"
                            onClick={(event) =>
                              setAnchorEl(
                                { index, currentTarget: event.currentTarget },
                                setClickedChipIndex(index),
                                setSearchTerm(""),
                                filter.displayName === "Date" &&
                                  setSelectCalenderDate(false)
                              )
                            }
                            onDelete={
                              hasFilters
                                ? () =>
                                    handleChipDelete(
                                      filter.filterOptions.queryParam,
                                      filter.filterOptions.queryParamInbound,
                                      filter.filterOptions.queryParamOutbound
                                    )
                                : null
                            }
                            // sx={{
                            //   backgroundColor: hasFilters(filter)
                            //     ? "black"
                            //     : "transparent",
                            //   color: hasFilters(filter) ? "white" : "black",
                            //   "& .MuiChip-deleteIcon": {
                            //     display: hasFilters(filter)
                            //       ? "inherit"
                            //       : "none",
                            //     color: "white", background:"plum"
                            //   },
                            // }}
                          >
                            <Typography className="placehoder-text">
                              {filter?.displayName === "Status" &&
                              statusLength > 0 ? (
                                <div
                                  style={{
                                    display: "flex",
                                  }}
                                >
                                  {filters?.status
                                    ?.slice(0, 3)
                                    .map((each, index) => {
                                      return (
                                        <div key={each}>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              alignItems: "center",
                                              padding: "0px 5px",
                                              marginRight: "5px",
                                            }}
                                            className="chip-background"
                                            key={index}
                                            onClick={(e) => e.stopPropagation()}
                                          >
                                            <Typography
                                              sx={{
                                                fontSize: "10px",
                                                color: "black",
                                              }}
                                            >
                                              {each}
                                            </Typography>
                                            <IconButton
                                              onClick={(e) => {
                                                handleFilterCheckboxChange(
                                                  filter.filterOptions
                                                    .queryParam,
                                                  each
                                                );
                                                e.stopPropagation();
                                              }}
                                            >
                                              <XCircleIcon
                                                sx={{ fontSize: "15px" }}
                                              />
                                            </IconButton>
                                          </Box>
                                        </div>
                                      );
                                    })}
                                  <Typography
                                    variant="h5"
                                    sx={{ marginTop: "10px" }}
                                  >
                                    {statusLength > 3 && "..."}
                                  </Typography>
                                </div>
                              ) : (
                                filter?.displayName === "Status" &&
                                `Select  ${filter.displayName}`
                              )}

                              {filter.displayName === "Amount" &&
                                `Select  ${filter.displayName}`}
                              {filter.displayName === "Merchant Reference Id" &&
                                `Select  ${filter.displayName}`}
                              {/* {filter.displayName === "Date" && getDate
                                ? todayDate || yesterdayData
                                  ? filters.transactionTime_from?.slice(0, 10)
                                  : `${filters?.transactionTime_from?.slice(
                                      0,
                                      10
                                    )} To ${filters?.transactionTime_to?.slice(
                                      0,
                                      10
                                    )}`
                                : selectCalenderDate &&
                                  filter.displayName === "Date"
                                ? `${filters?.transactionTime_from?.slice(
                                    0,
                                    10
                                  )} To ${filters?.transactionTime_to?.slice(
                                    0,
                                    10
                                  )}`
                                : filter.displayName === "Date" &&
                                  `Select  ${filter.displayName}`} */}
                              {filter.displayName === "Processor Recon Id" &&
                                `Select  ${filter.displayName}`}
                              {filter.displayName === "Processor Id" &&
                                `Select  ${filter.displayName}`}

                              {filter?.displayName === "Payment Type" &&
                              paymentMethodLength > 0 ? (
                                <div
                                  style={{
                                    display: "flex",
                                  }}
                                >
                                  {filters?.paymentMethod
                                    ?.slice(0, 3)
                                    .map((each, index) => {
                                      return (
                                        <div key={each}>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              alignItems: "center",
                                              padding: "0px 5px",
                                              marginRight: "5px",
                                            }}
                                            className="chip-background"
                                            key={index}
                                            onClick={(e) => e.stopPropagation()}
                                          >
                                            <Typography
                                              sx={{
                                                fontSize: "10px",
                                                color: "black",
                                              }}
                                            >
                                              {each}
                                            </Typography>
                                            <IconButton
                                              onClick={(e) => {
                                                handleFilterCheckboxChange(
                                                  filter.filterOptions
                                                    .queryParam,
                                                  each
                                                );
                                                e.stopPropagation();
                                              }}
                                            >
                                              <XCircleIcon
                                                sx={{ fontSize: "15px" }}
                                              />
                                            </IconButton>
                                          </Box>
                                        </div>
                                      );
                                    })}
                                  <Typography
                                    variant="h5"
                                    sx={{ marginTop: "10px" }}
                                  >
                                    {paymentMethodLength > 3 && "..."}
                                  </Typography>
                                </div>
                              ) : (
                                filter?.displayName === "Payment Type" &&
                                `Select  ${filter.displayName}`
                              )}

                              {filter?.displayName === "Currency Code" &&
                              currencyLength > 0 ? (
                                <div
                                  style={{
                                    display: "flex",
                                  }}
                                >
                                  {filters?.currency
                                    ?.slice(0, 3)
                                    .map((each, index) => {
                                      return (
                                        <div key={each}>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              alignItems: "center",
                                              padding: "0px 5px",
                                              marginRight: "5px",
                                            }}
                                            className="chip-background"
                                            key={index}
                                            onClick={(e) => e.stopPropagation()}
                                          >
                                            <Typography
                                              sx={{
                                                fontSize: "10px",
                                                color: "black",
                                              }}
                                            >
                                              {each}
                                            </Typography>
                                            <IconButton
                                              onClick={(e) => {
                                                handleFilterCheckboxChange(
                                                  filter.filterOptions
                                                    .queryParam,
                                                  each
                                                );
                                                e.stopPropagation();
                                              }}
                                            >
                                              <XCircleIcon
                                                sx={{ fontSize: "15px" }}
                                              />
                                            </IconButton>
                                          </Box>
                                        </div>
                                      );
                                    })}
                                  <Typography
                                    variant="h5"
                                    sx={{ marginTop: "10px" }}
                                  >
                                    {currencyLength > 3 && "..."}
                                  </Typography>
                                </div>
                              ) : (
                                filter?.displayName === "Currency Code" &&
                                `Select  ${filter.displayName}`
                              )}

                              {filter?.displayName === "Processor Code" &&
                              processorCodeLength > 0 ? (
                                <div
                                  style={{
                                    display: "flex",
                                  }}
                                >
                                  {filters?.processorCode
                                    ?.slice(0, 3)
                                    .map((each, index) => {
                                      return (
                                        <div key={each}>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              alignItems: "center",
                                              padding: "0px 5px",
                                              marginRight: "5px",
                                            }}
                                            key={index}
                                            className="chip-background"
                                            onClick={(e) => e.stopPropagation()}
                                          >
                                            <Typography
                                              sx={{
                                                fontSize: "10px",
                                                color: "black",
                                              }}
                                            >
                                              {each}
                                            </Typography>
                                            <IconButton
                                              onClick={(e) => {
                                                handleFilterCheckboxChange(
                                                  filter.filterOptions
                                                    .queryParam,
                                                  each
                                                );
                                                e.stopPropagation();
                                              }}
                                            >
                                              <XCircleIcon
                                                sx={{ fontSize: "15px" }}
                                              />
                                            </IconButton>
                                          </Box>
                                        </div>
                                      );
                                    })}
                                  <Typography
                                    variant="h5"
                                    sx={{ marginTop: "10px" }}
                                  >
                                    {processorCodeLength > 3 && "..."}
                                  </Typography>
                                </div>
                              ) : (
                                filter?.displayName === "Processor Code" &&
                                `Select  ${filter.displayName}`
                              )}

                              {filter?.displayName === "Transaction Type" &&
                              transactionTypeLength > 0 ? (
                                <div
                                  style={{
                                    display: "flex",
                                  }}
                                >
                                  {filters?.transactionType
                                    ?.slice(0, 3)
                                    .map((each, index) => {
                                      return (
                                        <div key={each}>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              alignItems: "center",
                                              padding: "0px 5px",
                                              marginRight: "5px",
                                            }}
                                            key={index}
                                            className="chip-background"
                                            onClick={(e) => e.stopPropagation()}
                                          >
                                            <Typography
                                              sx={{
                                                fontSize: "10px",
                                                color: "black",
                                              }}
                                            >
                                              {each}
                                            </Typography>
                                            <IconButton
                                              onClick={(e) => {
                                                handleFilterCheckboxChange(
                                                  filter.filterOptions
                                                    .queryParam,
                                                  each
                                                );
                                                e.stopPropagation();
                                              }}
                                            >
                                              <XCircleIcon
                                                sx={{ fontSize: "15px" }}
                                              />
                                            </IconButton>
                                          </Box>
                                        </div>
                                      );
                                    })}
                                  <Typography
                                    variant="h5"
                                    sx={{ marginTop: "10px" }}
                                  >
                                    {transactionTypeLength > 3 && "..."}
                                  </Typography>
                                </div>
                              ) : (
                                filter?.displayName === "Transaction Type" &&
                                `Select  ${filter.displayName}`
                              )}
                            </Typography>
                            {Boolean(anchorEl && anchorEl.index === index) ? (
                              <ExpandLessIcon className="cart-down-icon" />
                            ) : (
                              <CaretDown className="cart-down-icon" />
                            )}
                          </div>
                        </Box>

                        <Menu
                          className="pop-up-alignment"
                          anchorEl={
                            anchorEl && anchorEl.index === index
                              ? anchorEl.currentTarget
                              : null
                          }
                          open={Boolean(anchorEl && anchorEl.index === index)}
                          onClose={() => {
                            setAnchorEl(null);
                            setClickedChipIndex(null);
                            setFromButtonStatus(false);
                            setToButtonStatus(false);
                            setDate((prev) => ({
                              ...prev,
                              fromDate: "",
                              toDate: "",
                            }));
                            setTime((prev) => ({
                              ...prev,
                              fromTime: "",
                              toTime: "",
                            }));
                          }}
                        >
                          {filter.filterOptions.type === "INTEGER" && (
                            <div>
                              <MenuItem className="drop-down-menu-item">
                                <TextField
                                  key=""
                                  size="small"
                                  type="number"
                                  label="From"
                                  placeholder="From"
                                  fullWidth
                                  value={filters.amount_from}
                                  onChange={(e) =>
                                    handleFilterChange(
                                      filter.filterOptions.queryParamOutbound,
                                      e.target.value
                                    )
                                  }
                                />
                              </MenuItem>
                              <MenuItem>
                                <TextField
                                  key=""
                                  size="small"
                                  type="number"
                                  fullWidth
                                  label="To"
                                  placeholder="To"
                                  value={filters.amount_to}
                                  onChange={(e) =>
                                    handleFilterChange(
                                      filter.filterOptions.queryParamInbound,
                                      e.target.value
                                    )
                                  }
                                />
                              </MenuItem>
                            </div>
                          )}
                          {filter.filterOptions.type === "DATE" && (
                            <div style={{ padding: "0px 10px" }}>
                              <FunctionsProvider>
                                <Box
                                  sx={{
                                    display: "flex",
                                    gap: 2,
                                  }}
                                >
                                  <Today
                                    filter={filter}
                                    handleFilterChange={handleFilterChange}
                                    getDate={setGetDate}
                                    fetchTransactionDataWithFilter={
                                      reloadFilters
                                    }
                                    handleClose={handleClose}
                                    setDateLabel={setDateLabel}
                                    setTodayDate={setTodayDate}
                                    setYesterdayDate={setYesterdayDate}
                                  />
                                  <Yesterday
                                    filter={filter}
                                    handleFilterChange={handleFilterChange}
                                    getDate={setGetDate}
                                    fetchTransactionDataWithFilter={
                                      reloadFilters
                                    }
                                    handleClose={handleClose}
                                    setDateLabel={setDateLabel}
                                    setTodayDate={setTodayDate}
                                    setYesterdayDate={setYesterdayDate}
                                  />
                                  <Last7Days
                                    filter={filter}
                                    handleFilterChange={handleFilterChange}
                                    getDate={setGetDate}
                                    fetchTransactionDataWithFilter={
                                      reloadFilters
                                    }
                                    handleClose={handleClose}
                                    setDateLabel={setDateLabel}
                                    setTodayDate={setTodayDate}
                                    setYesterdayDate={setYesterdayDate}
                                  />
                                  <Last30Days
                                    filter={filter}
                                    handleFilterChange={handleFilterChange}
                                    getDate={setGetDate}
                                    fetchTransactionDataWithFilter={
                                      reloadFilters
                                    }
                                    handleClose={handleClose}
                                    setDateLabel={setDateLabel}
                                    setTodayDate={setTodayDate}
                                    setYesterdayDate={setYesterdayDate}
                                  />
                                </Box>
                              </FunctionsProvider>

                              <div style={{ display: "flex" }}>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DemoContainer
                                    components={["DatePicker", "TimeClock"]}
                                  >
                                    <Box sx={{ display: "flex", gap: "10px" }}>
                                      <DemoItem>
                                        <h3 style={{ fontSize: "15px" }}>
                                          From Date
                                        </h3>
                                        <DatePicker
                                          sx={{
                                            height: "40px",
                                            "& .MuiInputBase-root": {
                                              height: "100%",
                                            },
                                          }}
                                          onChange={(newFromDate) =>
                                            handleFromDateChange(
                                              convertFromDate(newFromDate.$d)
                                            )
                                          }
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Text"
                                            />
                                          )}
                                        />

                                        <DemoItem>
                                          <StaticTimePicker
                                            onChange={(time) => {
                                              handleFromTimeChange(
                                                convertFromTime(time),
                                                filter
                                              );
                                            }}
                                            value={toTime}
                                          />
                                        </DemoItem>
                                        {/* <Button
                                        disabled={fromButtonStatus}
                                        variant="outlined"
                                        onClick={() =>
                                          SelectFromTime("click", filter)
                                        }
                                      >
                                        Ok
                                      </Button> */}
                                      </DemoItem>
                                      <DemoItem>
                                        <h3 style={{ fontSize: "15px" }}>
                                          To Date
                                        </h3>

                                        <DatePicker
                                          sx={{
                                            height: "40px",
                                            "& .MuiInputBase-root": {
                                              height: "100%",
                                            },
                                          }}
                                          onChange={(newFromDate) =>
                                            handleToDateChange(
                                              convertToDate(newFromDate.$d)
                                            )
                                          }
                                          renderInput={(params) => (
                                            <TextField
                                              sx={{}}
                                              {...params}
                                              value=""
                                              placeholder="Text"
                                            />
                                          )}
                                        />

                                        <DemoItem>
                                          <StaticTimePicker
                                            onChange={(time) => {
                                              handleToTimeChange(
                                                convertToTime(time),
                                                filter
                                              );
                                            }}
                                            value={toTime}
                                          />
                                        </DemoItem>
                                        {/* <Button
                                        disabled={toButtonStatus}
                                        variant="outlined"
                                        onClick={() =>
                                          selectToTime("click", filter)
                                        }
                                      >
                                        Ok
                                      </Button> */}
                                      </DemoItem>
                                    </Box>
                                  </DemoContainer>
                                </LocalizationProvider>
                              </div>
                            </div>
                          )}
                          {filter.filterOptions.type === "ENUM" && (
                            <MenuItem className={paymentTypePopupHeight}>
                              <TextField
                                className="dropdown-search-field"
                                type="search"
                                size="small"
                                label="Search"
                                value={searchTerm}
                                fullWidth
                                onChange={(e) => {
                                  setSearchTerm(e.target.value);
                                }}
                                onKeyDown={(e) => e.stopPropagation()}
                              />

                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={
                                        filters[
                                          filter?.filterOptions.queryParam
                                        ]?.length ===
                                        filter?.filterOptions.options?.length
                                      }
                                      onChange={(e) => {
                                        handleSelectAllOptions(
                                          filter?.filterOptions.queryParam,
                                          filter?.filterOptions.options
                                        );
                                        e.stopPropagation();
                                      }}
                                    />
                                  }
                                  label="Select All"
                                />
                                <hr
                                  style={{
                                    background: "grey",
                                    height: "1px",
                                    width: "100%",
                                  }}
                                />

                                {filter.filterOptions.options
                                  .filter((option) =>
                                    option
                                      .toLowerCase()
                                      .includes(searchTerm.toLowerCase())
                                  )
                                  .map((option) => {
                                    return (
                                      <FormControlLabel
                                        key={option}
                                        control={
                                          <Checkbox
                                            checked={
                                              filters[
                                                filter.filterOptions.queryParam
                                              ]?.length ===
                                              filter.filterOptions.options
                                                ?.length
                                                ? true
                                                : (
                                                    selectedOptionsMap[
                                                      filter.filterOptions
                                                        .queryParam
                                                    ] || []
                                                  )?.includes(option)
                                            }
                                            onChange={(e) => {
                                              handleFilterCheckboxChange(
                                                filter.filterOptions.queryParam,
                                                option
                                              );
                                              e.stopPropagation();
                                            }}
                                            value={option}
                                          />
                                        }
                                        label={option}
                                      />
                                    );
                                  })}
                              </FormGroup>
                            </MenuItem>
                          )}
                          {filter.filterOptions.type !== "INTEGER" &&
                            filter.filterOptions.type !== "ENUM" &&
                            filter.filterOptions.type !== "DATE" && (
                              <MenuItem className="drop-down-menu-item">
                                <TextField
                                  size="small"
                                  type="search"
                                  fullWidth
                                  label={filter.displayName}
                                  placeholder={`Enter ${filter.displayName}`}
                                  value={
                                    filters[filter.filterOptions.queryParam]
                                  }
                                  onChange={(e) =>
                                    handleFilterChange(
                                      filter.filterOptions.queryParam,
                                      e.target.value
                                    )
                                  }
                                />
                              </MenuItem>
                            )}
                          <MenuItem
                            sx={{
                              display: "flex",
                              gap: "10px",
                              justifyContent: "space-between",
                            }}
                          >
                            {filter.filterOptions.type === "STRING" ? (
                              ""
                            ) : (
                              <>
                                <Button
                                  size="small"
                                  variant="outlined"
                                  color="inherit"
                                  onClick={() => {
                                    if (filter.displayName === "Date") {
                                      setDate((prev) => ({
                                        ...prev,
                                        fromDate: "",
                                        toDate: "",
                                      }));
                                      setTime((prev) => ({
                                        ...prev,
                                        fromTime: "",
                                        toTime: "",
                                      }));

                                      delete filters.transactionTime_from;
                                      delete filters.transactionTime_to;

                                      handleClose();
                                    } else if (
                                      filter.displayName === "Amount"
                                    ) {
                                      // setSelectedOptionsMap((prev) => ({
                                      //   ...prev,
                                      //   amount_from: "",
                                      //   amount_to: "",
                                      // }));
                                      setAmount(false);
                                      delete filters.amount_from;
                                      delete filters.amount_to;
                                      handleClose();
                                    } else if (filter.displayName !== "Date") {
                                      resetFilters(
                                        filter.filterOptions.queryParam
                                      );
                                      // resetData();
                                      setFromButtonStatus(false);
                                      setToButtonStatus(false);
                                      // setDate((prev) => ({
                                      //   ...prev,
                                      //   fromDate: "",
                                      //   toDate: "",
                                      // }));
                                      // setTime((prev) => ({
                                      //   ...prev,
                                      //   fromTime: "",
                                      //   toTime: "",
                                      // }));
                                      //setFilters([]);
                                      setDateLabel(false);
                                      setGetDate(false);
                                      handleClose();

                                      filter.displayName ===
                                        "Merchant Reference Id" &&
                                        setMerchantReferenceId(false);
                                      filter.displayName === "Amount" &&
                                        // setAmount(false);
                                        filter.displayName === "Date" &&
                                        setDateLabel(false);
                                      filter.displayName ===
                                        "Processor Recon Id" &&
                                        setProcessorReconId(false);
                                      filter.displayName === "Processor Id" &&
                                        setProcessorId(false);
                                    }

                                    // resetFilters(
                                    //   filter.filterOptions.queryParam
                                    // );
                                    // // resetData();
                                    // setFromButtonStatus(false);
                                    // setToButtonStatus(false);
                                    // setDate((prev) => ({
                                    //   ...prev,
                                    //   fromDate: "",
                                    //   toDate: "",
                                    // }));
                                    // setTime((prev) => ({
                                    //   ...prev,
                                    //   fromTime: "",
                                    //   toTime: "",
                                    // }));
                                    // //setFilters([]);
                                    // setDateLabel(false);
                                    // setGetDate(false);
                                    // handleClose();

                                    // filter.displayName ===
                                    //   "Merchant Reference Id" &&
                                    //   setMerchantReferenceId(false);
                                    // filter.displayName === "Amount" &&
                                    //   setAmount(false);
                                    // filter.displayName === "Date" &&
                                    //   setDateLabel(false);
                                    // filter.displayName ===
                                    //   "Processor Recon Id" &&
                                    //   setProcessorReconId(false);
                                    // filter.displayName === "Processor Id" &&
                                    //   setProcessorId(false);
                                  }}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  // disabled={
                                  //   filter.filterOptions.type === "DATE"
                                  //     ? !dateApplyButtonStatus
                                  //     : false
                                  // }
                                  size="small"
                                  variant="outlined"
                                  color="inherit"
                                  onClick={() => {
                                    if (twoDatesValidation) {
                                      applyfilters(filter);
                                      setFromButtonStatus(false);
                                      setToButtonStatus(false);
                                      setDate((prev) => ({
                                        ...prev,
                                        fromDate: "",
                                        toDate: "",
                                      }));
                                      setTime((prev) => ({
                                        ...prev,
                                        fromTime: "",
                                        toTime: "",
                                      }));
                                      reloadFilters();
                                      filter.displayName ===
                                        "Merchant Reference Id" &&
                                        setMerchantReferenceId(true);
                                      // filter.displayName === "Amount" &&
                                      //   setAmount(true);
                                      // filter.displayName === "Date" &&
                                      //   setDateLabel(true);
                                      filter.displayName ===
                                        "Processor Recon Id" &&
                                        setProcessorReconId(true);
                                      filter.displayName === "Processor Id" &&
                                        setProcessorId(true);
                                      setSelectCalenderDate(true);
                                    } else if (filter.displayName !== "Date") {
                                      applyfilters(filter);
                                      setFromButtonStatus(false);
                                      setToButtonStatus(false);
                                      // setDate((prev) => ({
                                      //   ...prev,
                                      //   fromDate: "",
                                      //   toDate: "",
                                      // }));
                                      // setTime((prev) => ({
                                      //   ...prev,
                                      //   fromTime: "",
                                      //   toTime: "",
                                      // }));
                                      //reloadFilters();
                                      //  handleOkClick();
                                      filter.displayName ===
                                        "Merchant Reference Id" &&
                                        setMerchantReferenceId(true);
                                      // filter.displayName === "Amount" &&
                                      //   setAmount(true);
                                      // filter.displayName === "Date" &&
                                      //   setDateLabel(true);
                                      filter.displayName ===
                                        "Processor Recon Id" &&
                                        setProcessorReconId(true);
                                      filter.displayName === "Processor Id" &&
                                        setProcessorId(true);
                                      //setSelectCalenderDate(true);
                                    }
                                  }}
                                >
                                  Ok
                                </Button>
                              </>
                            )}
                          </MenuItem>
                        </Menu>
                      </Box>
                    );
                  })}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                  }}
                  className="apply-cancel-button-container"
                >
                  <Box>
                    <Tooltip title="Cancel">
                      <Button
                        variant="outlined"
                        className="cancel-btn-text"
                        startIcon={<XCircleIcon />}
                        onClick={() => {
                          setFilters([]);
                          setSelectedOptionsMap([]);
                          fetchTransactionData();
                          setGetDate(false);
                          setDateLabel(false);
                          setSelectCalenderDate(false);
                          setAmount(false);
                          setDropdownFilter(!dropdownFilter);
                          // setFilters((prev) => {
                          //   return {
                          //     ...prev,
                          //     merchantReferenceId: "",
                          //     processorReconId: "",
                          //     processorTransactionId: "",
                          //   };
                          // });
                          // delete filters.merchantReferenceId;
                        }}
                      >
                        Cancel
                      </Button>
                    </Tooltip>
                    <Tooltip title="Apply">
                      <Button
                        variant="contained"
                        className="save-btn-text"
                        startIcon={<SaveIcon />}
                        onClick={() => {
                          reloadFilters();
                          if (filters?.amount_to?.length > 0) {
                            setAmount(true);
                          }
                          // setSelectCalenderDate(true);
                          //setDateLabel(true);
                        }}
                      >
                        Apply
                      </Button>
                    </Tooltip>
                  </Box>
                </Box>
              </div>
            )}

            {/*-------------------------------------Filter-Changes---------------------------------------------*/}

            {warning && (
              <Alert severity="error">
                Something went wrong while fetching the transactions
              </Alert>
            )}

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flexWrap: "wrap",
                overflowX: "scroll",
                // width: "100%",
                //position: "relative",
                //overflow: "hidden",
              }}
              className={
                toggleExpand ? "expanded-table-details" : "table-details"
              }
            >
              <TableContainer
                component={Paper}
                sx={{
                  backgroundColor: "#F5F7FA",
                  maxWidth: "100%",
                  minWidth: "700 !important",
                  overflowX: "scroll !important",
                  "&::-webkit-scrollbar": {
                    height: "8px",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#888",
                    borderRadius: "4px",
                  },
                }}
              >
                <Table
                  aria-label="customized table"
                  sx={{
                    minWidth: 700,
                    overflowX: "scroll",
                  }}
                >
                  <TableHead>
                    <TableRow>
                      {/* data.ListOfShowingColumns     */}
                      {data.ListOfShowingColumns.map((columnKey) => {
                        const getWidth = () => {
                          if (
                            data.transactionsMap[columnKey].displayName ===
                            "Merchant Reference Id"
                          ) {
                            return "170px"; //180
                          } else if (
                            data.transactionsMap[columnKey].displayName ===
                            "Transaction Type"
                          ) {
                            return "130px"; //140
                          } else if (
                            data.transactionsMap[columnKey].displayName ===
                            "Payment Type"
                          ) {
                            return "115px"; //120
                          } else if (
                            data.transactionsMap[columnKey].displayName ===
                            "Currency Code"
                          ) {
                            return "117px"; //150
                          } else if (
                            data.transactionsMap[columnKey].displayName ===
                            "Processor Code"
                          ) {
                            return "124px"; //130
                          } else if (
                            data.transactionsMap[columnKey].displayName ===
                            "Date"
                          ) {
                            return "100px";
                          } else if (
                            data.transactionsMap[columnKey].displayName ===
                            "Processor Recon Id"
                          ) {
                            return "150px"; //160
                          } else if (
                            data.transactionsMap[columnKey].displayName ===
                            "Processor Id"
                          ) {
                            return "102px"; //120
                          }
                        };

                        return (
                          <StyledTableCell
                            key={columnKey}
                            className="table-styled-cell"
                            sx={{
                              padding: "15px 2px 15px 2px",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                // justifyContent: "space-between",
                                width: getWidth(),
                              }}
                            >
                              <Typography
                                className="table-header-text"
                                sx={{ textTransform: "capitalize" }}
                              >
                                {data.transactionsMap[columnKey].displayName}
                                {/* {columnKey} */}
                              </Typography>
                              <Box
                                sx={{
                                  height: "20px",
                                  marginLeft: "1px",
                                }}
                              >
                                <ExpandUpdownFill />
                              </Box>
                            </Box>
                          </StyledTableCell>
                        );
                      })}
                      <Select
                        className="add-table-column"
                        labelId="select-columns-label"
                        value=""
                        IconComponent={AddIcon}
                      >
                        {Object.keys(data.transactionsMap).map((columnKey) => (
                          <MenuItem
                            key={columnKey}
                            value={columnKey}
                            sx={{ padding: "0px 5px 0px 0px", margin: "0px" }}
                          >
                            <Checkbox
                              checked={checkedColumns.includes(columnKey)}
                              onChange={handleCheckboxChange(columnKey)}
                              sx={{ padding: "5px" }}
                            />
                            {data.transactionsMap[columnKey].displayName}
                          </MenuItem>
                        ))}
                      </Select>
                    </TableRow>
                  </TableHead>
                  {isLoading ? (
                    <TableBody>
                      <tr>
                        <td
                          colSpan={data.ListOfShowingColumns.length}
                          style={{ textAlign: "center" }}
                        >
                          <DNA
                            visible={true}
                            height="80"
                            width="80"
                            ariaLabel="dna-loading"
                            wrapperStyle={{}}
                            wrapperClass="dna-wrapper"
                          />
                        </td>
                      </tr>
                    </TableBody>
                  ) : (
                    <>
                      <TableBody>
                        <>
                          {filterData?.length > 0 ? (
                            filterData?.map((item, key) => {
                              return (
                                <>
                                  <StyledTableRow key={key}>
                                    {/* {data.ListOfShowingColumns} */}
                                    {data.ListOfShowingColumns.map(
                                      (columnKey) => {
                                        // console.log(columnKey, "key");
                                        const getTableDataWidth = () => {
                                          if (columnKey === "id") {
                                            return "120px";
                                          } else if (
                                            columnKey === "processorId"
                                          ) {
                                            return "120px";
                                          } else if (columnKey === "date") {
                                            return "120px";
                                          }
                                        };

                                        return (
                                          <StyledTableCell
                                            key={columnKey}
                                            sx={{
                                              background: "#FFF",
                                              padding: "5px",
                                              //whiteSpace: "nowrap",
                                              //overflow: "hidden",
                                              //textOverflow: "ellipsis",
                                            }}
                                          >
                                            <Typography
                                              className={`table-data-text settings-title `}
                                              // {settings-title we took from Home ---> Settings, table body data}
                                              sx={{ width: getTableDataWidth }}
                                            >
                                              {renderTableCell(
                                                item,
                                                columnKey,
                                                data
                                              )}
                                            </Typography>
                                          </StyledTableCell>
                                        );
                                      }
                                    )}
                                    <StyledTableCell
                                      sx={{
                                        background: "#FFF",
                                        // marginLeft: "-50px",
                                      }}
                                    >
                                      <div style={{ marginLeft: "-45px" }}>
                                        <Popup
                                          trigger={
                                            <Box>
                                              <IconButton>
                                                <DotsThreeVertical />
                                              </IconButton>
                                            </Box>
                                          }
                                          position="bottom left"
                                          contentStyle={{
                                            width: "70px",
                                            marginLeft: "-10px",
                                          }}
                                        >
                                          <Box
                                            sx={{
                                              display: "flex",
                                              alignItems: "center",
                                              padding: "4px 0px",
                                              gap: "4px",
                                              cursor: "pointer",
                                            }}
                                            onClick={() =>
                                              handleLinkClick(
                                                item.id,
                                                item.transactionType
                                              )
                                            }
                                          >
                                            <Box
                                              sx={{
                                                height: "20px",
                                                width: "20px",
                                              }}
                                            >
                                              <FileText />
                                            </Box>
                                            <Typography className="details-text">
                                              Details
                                            </Typography>
                                          </Box>
                                        </Popup>
                                      </div>
                                    </StyledTableCell>
                                  </StyledTableRow>
                                </>
                              );
                            })
                          ) : (
                            <Typography className="not-matched-text">
                              No Matching Data Found
                            </Typography>
                          )}
                        </>
                      </TableBody>
                    </>
                  )}
                </Table>
              </TableContainer>
              <TableFooter
                sx={{
                  display: "flex",
                  // background: "plum",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  margin: "15px 0px",

                  // paddingRight: "35px",
                }}
              >
                <tr>
                  <td colSpan={data.ListOfShowingColumns.length}>
                    <div>
                      <Pagination
                        count={10}
                        page={page}
                        onChange={handleChangePage}
                        variant="outlined"
                        shape="rounded"
                        className="pagination-buttons"
                      />
                    </div>
                  </td>
                </tr>
              </TableFooter>
            </div>
          </div>
        ) : (
          <TransactionDetails
            id={clickedLinkId}
            transactionType={selectedTransactionType}
            handlingDetailsClose={handleDetailsClose}
            transactionsData={transactions}
          />
        )}
      </div>
    </FunctionsProvider>
  );
}

function getFirstNonNullValue(item, path) {
  const fields = path.split(",").map((field) => field.trim());
  for (const field of fields) {
    const value = data.getColumnValue(item, field);
    if (value !== null && value !== undefined) {
      return value;
    }
  }
  // If all fields are null or undefined, return an empty string or any default value you prefer
  return "";
}

export default Transactions;
