import React, { useState, useLayoutEffect } from "react";
import { TableHeaderCell, LabelCont, TableRowCell } from "./StyledComponents";

import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  Button,
  Alert,
  Box,
  Typography,
} from "@mui/material";
import CopyIcon from "@mui/icons-material/FileCopy";
import RefreshIcon from "@mui/icons-material/Refresh";
import { DNA } from "react-loader-spinner";
import {
  URL,
  API_ENDPOINT,
  API_REGENERATE_ENDPOINT,
} from "constants/Constants";
import { httpClient } from "utils/HttpClient";
import { StyledTableCell, StyledTableRow } from "utils/Styles";
import "../ApiCredentials/ApiCredentials.css";
import ExpandUpdownFill from "components/Icons/ExpandUpdownFill";

function ApiCredentials() {
  const [data, setData] = useState({});
  const [isGenerated, setIsGenerated] = useState({
    public: false,
    private: false,
  });
  const [isLoading, setIsLoading] = useState(true);
  useLayoutEffect(() => {
    fetchApiCredentialsData();
  }, []);

  const fetchApiCredentialsData = async () => {
    httpClient.fetchData(`${URL}${API_ENDPOINT}`, "GET").then((data) => {
      console.log(data);
      setData(data);
      setIsLoading(false);
    });
  };

  const handleAction = async (type, action) => {
    const Type = type.toLowerCase();

    console.log(`Action: ${action} for ${type}`);

    if (action === "regenerate") {
      httpClient
        .fetchData(`${URL}${API_REGENERATE_ENDPOINT}&type=${Type}`, "GET")
        .then((responseData) => {
          setIsGenerated((prev) => ({ ...prev, [Type]: true }));
          setData((prevData) => ({
            ...prevData,
            [Type]: responseData && responseData[Type],
          }));
        });
    }
  };

  const renderTableRow = (data) => {
    return (
      <>
        <StyledTableRow style={{ display: "flex" }}>
          <TableRowCell className="public-label-container">
            <Typography className="public-text">Public</Typography>
          </TableRowCell>
          <TableRowCell className="password-label-container">
            <Typography className="password-text">
              {data?.public && data?.public?.length <= 6 && "****************"}
              {data.public ? data.public : "Not Generated"}
            </Typography>
          </TableRowCell>
          <TableRowCell className="regenerate-label-container">
            {isGenerated["public"] && (
              <Button
                sx={{
                  border: "none",
                  "&:hover": {
                    border: "none",
                  },
                }}
                variant="outlined"
                onClick={() => handleAction("public", "copy")}
                startIcon={<CopyIcon />}
              >
                Copy
              </Button>
            )}
            <Button
              sx={{
                border: "none",
                "&:hover": {
                  border: "none",
                },
              }}
              variant="outlined"
              className="refresh-button"
              onClick={() => handleAction("public", "regenerate")}
              startIcon={<RefreshIcon />}
            >
              <Typography className="generate-text">
                {data.public ? "Regenerate" : "Generate"}
              </Typography>
            </Button>
          </TableRowCell>
        </StyledTableRow>
        <Box className="hr-line"></Box>
        <StyledTableRow>
          <Typography>
            {isGenerated["public"] && (
              <Alert
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                icon={false}
                severity="info"
              >
                Please copy and save the key safely now itself, as it won't
                appear again.
              </Alert>
            )}
          </Typography>
        </StyledTableRow>
        <StyledTableRow style={{ display: "flex" }}>
          <TableRowCell className="private-label-container">
            <Typography className="private-text">Private</Typography>
          </TableRowCell>
          <TableRowCell className="not-generate-label-container">
            <Typography className="not-generate-text">
              {data?.private &&
                data?.private?.length <= 6 &&
                "****************"}
              {data?.private ? data?.private : "Not Generated"}
            </Typography>
          </TableRowCell>
          <TableRowCell className="regenerate-label-containers">
            {isGenerated["private"] && (
              <Button
                sx={{
                  border: "none",
                  "&:hover": {
                    border: "none",
                  },
                }}
                variant="outlined"
                onClick={() => handleAction("private", "copy")}
                startIcon={<CopyIcon />}
              >
                Copy
              </Button>
            )}
            <Button
              sx={{
                border: "none",
                "&:hover": {
                  border: "none",
                },
              }}
              variant="outlined"
              className="refresh-button"
              onClick={() => handleAction("private", "regenerate")}
              startIcon={<RefreshIcon />}
            >
              <Typography className="generate-text">
                {data?.private ? "Regenerate" : "Generate"}
              </Typography>
            </Button>
          </TableRowCell>
        </StyledTableRow>
        <StyledTableRow>
          <Typography>
            {isGenerated["private"] && (
              <Alert
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                icon={false}
                severity="info"
              >
                Please copy and save the key safely now itself, as it won't
                appear again.
              </Alert>
            )}
          </Typography>
        </StyledTableRow>
      </>
    );
  };

  return (
    <div>
      <TableContainer>
        <Table>
          <TableHead>
            <StyledTableRow className="table-row-header">
              {/* <TableHeaderCell className="name-label-container">
                <Typography className="name-text">Name</Typography>
                <LabelCont className="up-down-label-cont">
                  <ExpandUpdownFill />
                </LabelCont>
              </TableHeaderCell> */}
               <TableHeaderCell className="token-label-container">
                <LabelCont className="label-container">
                  <Typography className="token-text">Name</Typography>
                  <LabelCont className="up-down-label-cont">
                    <ExpandUpdownFill  className="updown-fill"/>
                  </LabelCont>
                </LabelCont>
              </TableHeaderCell>
              <TableHeaderCell className="token-label-container">
                <LabelCont className="label-container">
                  <Typography className="token-text">Token</Typography>
                  <LabelCont className="up-down-label-cont">
                    <ExpandUpdownFill  className="updown-fill"/>
                  </LabelCont>
                </LabelCont>
              </TableHeaderCell>
              <TableHeaderCell className="token-label-container">
                <LabelCont className="label-container">
                  <Typography className="token-text"></Typography>
                </LabelCont>
              </TableHeaderCell>
            </StyledTableRow>
          </TableHead>
          {isLoading ? (
            <TableBody>
              <tr>
                <td colSpan={3} style={{ textAlign: "center" }}>
                  <DNA
                    visible={true}
                    height="80"
                    width="80"
                    ariaLabel="dna-loading"
                    wrapperStyle={{}}
                    wrapperClass="dna-wrapper"
                  />
                </td>
              </tr>
            </TableBody>
          ) : (
            <TableBody>{renderTableRow(data)}</TableBody>
          )}
        </Table>
      </TableContainer>
    </div>
  );
}

export default ApiCredentials;
