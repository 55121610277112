import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./components/Home/Home";
import { httpClient } from "./utils/HttpClient.js";
import { KCClient } from "./utils/KC.js";
import Keycloak from "keycloak-js";
import {
  KEYCLOAK_URL,
  REALM,
  CLIENT_ID,
} from "constants/Constants";
import { jwtDecode } from "jwt-decode";
import { useDispatch } from "react-redux";
import { setMerchantId } from "./redux/reducers/merchant";
console.log(process.env);

function App() {
 
  const [authenticated, setAuthenticated] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    const keycloakInitParams = {
      url: KEYCLOAK_URL,
      realm: REALM,
      clientId: CLIENT_ID,
    };

    const kc = new Keycloak(keycloakInitParams);
    console.log(kc, "kc");

    kc.onTokenExpired = () => {
      console.log("token expired");
      kc.updateToken(10)
        .then((refreshed) => {
          console.log("refreshed - " + refreshed);
          httpClient.setHeader("Authorization", "Bearer " + kc.token);
        })
        .catch(() => {
          console.error("Failed to refresh token");
          kc.logout();
          // reload the home or login screen
        });
    };

    kc.init({
      onLoad: "login-required",
      checkLoginIframe: true,
      checkLoginIframeInterval: 300,
    }).then((auth) => {
      KCClient.setKC(kc);

      if (!auth) {
        window.location.reload();
      } else {
        setAuthenticated(true);
        console.log("authenticated");
        const getToken = jwtDecode(kc.token);
        console.log(getToken.merchantId, "get");
        
        dispatch(setMerchantId(getToken.merchantId));
        console.log(kc);
        httpClient.setHeader("Authorization", "Bearer " + kc.token);
      }
    });
  }, []); // Run only once on component mount

  if (!authenticated) {
    // Return a loading indicator or a login screen while waiting for authentication
    return (
      <div className="container-login">
        <img
          className="loading-indicator"
          src="logos/Loader.svg"
          alt="Loading..."
        />
      </div>
    );
  }

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/index.html" element={<Home />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
