import React, { useState } from "react";
import Menu from "components/Menu/Menu";
import Settings from "components/Settings/Settings";
import "./Home.css";
import HomeSettings from "./Settings";
import {
  MenuFunctionsProvider,
  useMenuFunctions,
} from "components/Menu/MenuFunctionProvider";
import { useService } from "constants/ServiceProvider";

function Home() {
  
  const [activeComponent, setActiveComponent] = useState(<Settings />);

  const [componentName, setComponentName] = useState("Settings");
  const [menuClicked, setMenuClicked] = useState(false);

  // const { toggleExpand, menuToggleExpand } = useMenuFunctions();

  const handleComponentOpen = (key, componentName) => {
    if (key && componentName) {
      setActiveComponent(key);
      setComponentName(componentName);
      setMenuClicked(true);
    }
  };

  return (
    <MenuFunctionsProvider>
      <div className="home-container">
        <Menu onClick={handleComponentOpen} />
        <div className="component-container">
          {menuClicked ? (
            <>
              {/* <div className="component-container-header">
            <p>{componentName}</p>
          </div> */}

              <div className="component-container-body">
                <div className="component-container-body">
                  {activeComponent}
                </div>
              </div>
            </>
          ) : (
            <HomeSettings />
          )}
        </div>
      </div>
    </MenuFunctionsProvider>
  );
}

export default Home;
